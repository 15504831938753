import React, { useState } from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { useAuth } from "../firebase/contexts/AuthContext";
import { ColorButton, RoundedCard, RoundedTextField } from "../MUI_Components/Components";
import { Button } from "@mui/material";
import { getDatabase, ref, push } from "firebase/database"; // Import Firebase database functions

const authErrors = {
  "auth/invalid-email": "Email address not valid",
  "auth/user-not-found": "User not found",
};

export default function ForgotPassword() {
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const email = data.get("email");

    if (!email) {
      setError("You must enter an email");
      return;
    }

    try {
      setError("");
      setMessage("");
      setLoading(true);
      await resetPassword(email);
      setMessage("Check your inbox for further instructions");
    } catch (err) {
      console.log(err.code);
      if (authErrors[err.code]) {
        setError(authErrors[err.code]);
      } else {
        setError("Failed to reset password");
      }
    }
    setLoading(false);
  }

  return (
    <div
      style={{
        height: "100dvh",
        width: "100dvw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <RoundedCard>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="assets/teldio_logo.png"
              alt="Teldio logo"
              style={{ width: "150px" }}
            />
            <Typography component="h1" variant="h5" sx={{ marginTop: "30px" }}>
              Forgot Password
            </Typography>
            {error && (
              <Alert severity="error" sx={{ marginTop: "5px" }}>
                {error}
              </Alert>
            )}
            {message && (
              <Alert severity="success" sx={{ marginTop: "5px" }}>
                {message}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3 }}
            >
              <RoundedTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ColorButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                >
                  Reset Password
                </ColorButton>
              </div>
              <div style={{display: "flex", justifyContent: "center"}}>
                <Button onClick={() => navigate("/login")}>
                  Remembered your password? Log in
                </Button>
              </div>
            </Box>
          </Box>
        </Container>
      </RoundedCard>
    </div>
  );
}
