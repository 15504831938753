import React, { useState } from "react";
import {
  Box,
  Modal,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { SquaredButtonGreen } from "../../MUI_Components/Components";
import { useAuth } from "../../firebase/contexts/AuthContext";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getFunctions, httpsCallable } from "firebase/functions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const NewUserPassword = ({ open, handleClose, fetchUserRole }) => {
  const { currentUser } = useAuth();
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const reauthenticate = async (currentPassword) => {
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );
    try {
      await reauthenticateWithCredential(currentUser, credential);
    } catch (error) {
      throw new Error("Re-authentication failed. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentPassword && newPassword) {
        // Reauthenticate the user
        await reauthenticate(currentPassword);

        // Update the user's password
        await updatePassword(currentUser, newPassword);

        // Call the cloud function to update the 'newUser' custom claim
        const functions = getFunctions();
        const updateNewUserField = httpsCallable(
          functions,
          "updateNewUserField"
        );
        await updateNewUserField();

        // Force token refresh to get updated custom claims
        await currentUser.getIdToken(true);

        setSnackbarSeverity("success");
        setSnackbarMessage("Password updated successfully!");
        setSnackbarOpen(true);

        setUnsavedChanges(false);

        // Close the modal after successful update
        handleClose();

        // Wait one second and then refresh the page
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  };

  const handleModalClose = () => {
    if (unsavedChanges) {
      setConfirmationOpen(true);
    } else {
      resetForm();
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmationOpen(false);
    resetForm();
    handleClose();
  };

  const handleCancelClose = () => {
    setConfirmationOpen(false);
  };

  const resetForm = () => {
    setNewPassword("");
    setCurrentPassword("");
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setSnackbarMessage("");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please create a new password
          </Typography>
          <Divider sx={{ my: 2 }} />
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Current Password"
              type={showCurrentPassword ? "text" : "password"}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="New Password"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setUnsavedChanges(true);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "16px",
              }}
            >
              <SquaredButtonGreen
                type="submit"
                variant="contained"
                color="primary"
                disabled={!currentPassword || !newPassword}
              >
                Save Changes
              </SquaredButtonGreen>
            </div>
          </form>
        </Box>
      </Modal>

      <Dialog open={confirmationOpen} onClose={handleCancelClose}>
        <DialogTitle>{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Are you sure you want to close without
            saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClose}
            color="error"
            variant="contained"
            autoFocus
          >
            Close Without Saving
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NewUserPassword;
