import React, { useContext, useState, useEffect } from "react";
import { confirmPasswordReset as firebaseConfirmPasswordReset } from "firebase/auth";
import { get, off, onValue, ref, set } from "firebase/database";
import { auth, getDB } from "../firebase";
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  // function login(email, password) {
  //   return auth.signInWithEmailAndPassword(email, password);
  // }

  function login(email, password) {
    const db = getDB();
    return auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Successfully signed in
        const user = userCredential.user;
        const lastLoginAt = Date.now();

        // Update lastLoginAt in the Realtime Database
        const userRef = ref(db, `/uid/${user.uid}/lastLoginAt/`);

        console.log(user.uid, lastLoginAt, userRef);
        return set(userRef, {time: lastLoginAt.toString()}).then(() => {
          return user;
        });
      });
  }

  function logout() {
    return auth.signOut();
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  // Function to confirm the password reset
  function confirmPasswordReset(oobCode, newPassword) {
    return firebaseConfirmPasswordReset(auth, oobCode, newPassword);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    updatePassword,
    resetPassword,
    confirmPasswordReset,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
