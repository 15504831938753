import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  CompositeDecorator,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { MentionSuggestions } from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import NotesIcon from "@mui/icons-material/Notes";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowUp";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  SquaredButtonGray,
  SquaredButtonGreen,
} from "../../../MUI_Components/Components";
import { themeDetails } from "../../../Helper/utils";
import { useSettings } from "../../../Contexts/SettingsContext";
import "./Description.css";
import createImagePlugin from "@draft-js-plugins/image";
import "@draft-js-plugins/image/lib/plugin.css";
import CustomImageComponent from "./DraftComponents/CreateImageComponent";

// Custom Link Component for rendering links
const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={{ color: "red", textDecoration: "underline" }}>
      {props.children}
    </a>
  );
};

// Strategy to find link entities in the editor content
const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

// Applying the decorator for link entities
const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);



const Description = ({
  description,
  setDescription,
  taskId,
  boardId,
  columnId,
  boardOwner,
  updateTask,
  collaborators,
  editingDescription,
  setEditingDescription,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(decorator)
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const editorWrapperRef = useRef(null);
  const editorRef = useRef(null);
  const { hasCommandModifier } = KeyBindingUtil;
  const { settings } = useSettings();
  const imagePlugin = createImagePlugin({
    component: (props) => (
      <CustomImageComponent
        {...props}
        blockProps={{
          onImageSelect: handleImageSelect,
          onImageDelete: handleImageDelete,
        }}
      />
    ),
  });
  const [selectedImageKey, setSelectedImageKey] = useState(null);
  const dynamicStyles = {
    "--editor-background-color":
      themeDetails[settings.cardColor]?.secondary || "white",
    "--button-background-color": themeDetails[settings.cardColor]?.primary,
    "--button-text-color": themeDetails[settings.cardColor]?.font,
    "--button-hover-background-color":
      themeDetails[settings.cardColor]?.secondary,
    "--custom-link-color": "#5dbcc2",
  };
  const handleImageSelect = (blockKey) => {
    setSelectedImageKey(blockKey); // Highlight or mark the image as selected
  };
  const handleImageDelete = (blockKey) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap().delete(blockKey); // Delete the image block
    const newContentState = contentState.merge({ blockMap });
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "remove-range"
    );
    setEditorState(newEditorState);
  };
  // Dynamic style for links
  const linkStyle = {
    color: "yellow" || "red", // Use the custom color or fallback to red
    textDecoration: "underline",
  };

  function myKeyBindingFn(e) {
    if (e.keyCode === 13 && hasCommandModifier(e)) {
      return "control-enter";
    }
    if (e.keyCode === 27) {
      return "ignore-escape"; // Custom command for Escape key
    }
    if (e.keyCode === 9) {
      return "ignore-escape"; // Custom command for Escape key
    }
    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command) => {
    if (command === "control-enter") {
      handleSaveDescription();
      return "handled";
    }
    if (command === "ignore-escape") {
      // Do nothing when Escape is pressed
      return "handled";
    }
    return "not-handled";
  };

  useEffect(() => {
    setSuggestions(
      collaborators.map((user) => ({
        text: user.displayName || user.email,
        value: user.displayName || user.email,
        url: user.photoURL,
      }))
    );
  }, [collaborators]);

  const checkHeight = () => {
    if (editorWrapperRef.current) {
      setShowButton(editorWrapperRef.current.scrollHeight > 400);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(checkHeight);
    if (editorWrapperRef.current) {
      resizeObserver.observe(editorWrapperRef.current);
    }
    return () => {
      if (editorWrapperRef.current) {
        resizeObserver.unobserve(editorWrapperRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setEditorState(() => {
      try {
        return description
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(description)),
              decorator
            )
          : EditorState.createEmpty(decorator);
      } catch (error) {
        console.error("Error initializing editor state", error);
        return EditorState.createEmpty(decorator);
      }
    });
  }, [description]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSaveDescription = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const newDescription = JSON.stringify(rawContentState);
    updateTask(boardId, columnId, taskId, { description: newDescription }).then(
      () => {
        setDescription(newDescription);
        setEditingDescription(false);
      }
    );
  };

  const handlePastedFiles = (files) => {
    files.forEach((file) => {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const src = e.target.result;
          const newState = imagePlugin.addImage(editorState, src);
          setEditorState(newState);
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const isEditorStateEmpty = (editorState) => {
    const contentState = editorState.getCurrentContent();
    return !contentState.hasText();
  };

  useEffect(() => {
    if (editingDescription && editorRef.current) {
      editorRef.current.focusEditor();
    }
  }, [editingDescription]);

  return (
    <Box
      sx={{ flex: 3, padding: "5px" }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.stopPropagation(); // Prevent event from propagating to the modal
        }
      }}
      style={dynamicStyles} // Applying dynamic styles here
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <NotesIcon />
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Description
        </Typography>
      </Stack>

      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        {!editingDescription && isEditorStateEmpty(editorState) && (
          <div
            style={{
              backgroundColor:
                themeDetails[settings.cardColor]?.secondary || "#F3F4F5",
              color: themeDetails[settings.cardColor]?.font || "black",
              minHeight: "50px",
              mb: "10px",
              cursor: "pointer",
            }}
            onClick={() => setEditingDescription(true)}
          >
            <Typography>Add a more detailed description...</Typography>
          </div>
        )}
        {editingDescription ? (
          <Box>
            <Box
              sx={{
                backgroundColor:
                  themeDetails[settings.cardColor]?.primary || "f9f9f9",
                borderRadius: "5px",
                border: "solid",
                borderColor: "#A0AD39",
                mb: "10px",
              }}
            >
              {editorState ? (
                <Editor
                  editorState={editorState}
                  editorClassName={"description-editor"}
                  onEditorStateChange={setEditorState}
                  editorStyle={{
                    backgroundColor:
                      themeDetails[settings.cardColor]?.primary || "f9f9f9",
                  }}
                  handlePastedFiles={handlePastedFiles}
                  plugins={[imagePlugin]}
                  toolbarStyle={{
                    backgroundColor:
                      themeDetails[settings.cardColor]?.primary || "f9f9f9",
                    borderColor: "transparent",
                  }}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "list",
                      "link",
                      "emoji",
                      "history",
                    ],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                    blockType: {
                      options: ["Normal", "H1", "H2", "H3", "Blockquote"],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                  }}
                  mention={{
                    separator: " ",
                    trigger: "@",
                    suggestions: suggestions,
                  }}
                  placeholder="Add a more detailed description..."
                  ref={(element) => {
                    editorRef.current = element;
                  }}
                />
              ) : null}
            </Box>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Button
                sx={{ mr: "20px" }}
                variant="text"
                color="error"
                onClick={() => setEditingDescription(false)}
              >
                Cancel
              </Button>
              <SquaredButtonGreen onClick={handleSaveDescription}>
                Save
              </SquaredButtonGreen>
            </div>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                position: "relative",
                borderRadius: "5px",
                minHeight: "200px",
                cursor: "pointer",
                maxHeight: isExpanded ? "none" : "300px",
                overflow: "hidden",
                transition: "max-height 0.3s ease",
                maskImage:
                  showButton && !isExpanded
                    ? "linear-gradient(to bottom, black 60%, transparent 100%)"
                    : "none",
                WebkitMaskImage:
                  showButton && !isExpanded
                    ? "linear-gradient(to bottom, black 60%, transparent 100%)"
                    : "none",
              }}
              onClick={() => setEditingDescription(true)}
              ref={editorWrapperRef}
            >
              <Box sx={{ position: "relative", paddingBottom: "40px" }}>
                {editorState ? (
                  <>
                    <Editor
                      editorClassName={"description-editor"}
                      editorState={editorState}
                      readOnly
                      toolbarHidden
                      mention={{
                        separator: " ",
                        trigger: "@",
                        suggestions: suggestions,
                      }}
                    />
                  </>
                ) : null}
              </Box>
            </Box>

            {showButton && (
              <SquaredButtonGray
                onClick={handleToggle}
                fullWidth
                startIcon={
                  isExpanded ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )
                }
              >
                {isExpanded ? "Show Less" : "Show More"}
              </SquaredButtonGray>
            )}
          </>
        )}
      </div>
    </Box>
  );
};

export default Description;
