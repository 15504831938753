import { Chip, Link } from "@mui/material";
import React from "react";

const TextRenderer = ({ text }) => {
  const urlPattern = /https?:\/\/[^\s]+/g;
  const mentionPattern = /@\[(.+?)\]\((.+?)\)/g; // Adjust to capture name and email separately

  // Combined pattern to handle both URLs and mentions
  const combinedPattern = new RegExp(
    `(${urlPattern.source})|(${mentionPattern.source})`,
    "g"
  );

  const addText = (str, isUrl = false) => {
    parts.push(
      isUrl ? (
        <Link
          key={parts.length}
          href={str}
          target="_blank"
          rel="noopener noreferrer"
        >
          {str}
        </Link>
      ) : (
        <span key={parts.length}>{str}</span>
      )
    );
  };

  const parts = [];

  text?.split("\n").forEach((line, lineIndex) => {
    let lastIndex = 0;

    line.replace(combinedPattern, (match, urlMatch, mentionFull, mentionName, mentionEmail, offset) => {
      if (offset > lastIndex) {
        addText(line.slice(lastIndex, offset)); // Add preceding text
      }

      if (urlMatch) {
        // Handle URLs
        addText(urlMatch, true);
      } else if (mentionName) {
        // Handle mentions, using mentionName (name between @[])
        parts.push(
          <Chip
            key={parts.length}
            label={`@${mentionName}`}
            variant="outlined"
            style={{
              marginRight: 2,
              backgroundColor: "#CAD957",
              border: "none",
              padding: 0,
              height: "25px",
            }}
          />
        );
      }

      lastIndex = offset + match.length;
    });

    if (lastIndex < line.length) {
      addText(line.slice(lastIndex)); // Add remaining text
    }

    if (lineIndex < text.split("\n").length - 1) {
      parts.push(<br key={`br-${parts.length}`} />);
    }
  });

  return <>{parts}</>;
};

export default TextRenderer;
