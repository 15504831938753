import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { useAuth } from "../firebase/contexts/AuthContext";
import { ColorButton, RoundedCard, RoundedTextField } from "../MUI_Components/Components";
import { Button } from "@mui/material";

const authErrors = {
  "auth/expired-action-code": "The reset link has expired",
  "auth/invalid-action-code": "The reset link is invalid",
  "auth/user-disabled": "This user has been disabled",
  "auth/user-not-found": "User not found",
  "auth/weak-password": "Password is too weak",
};

export default function ResetPassword() {
  const { confirmPasswordReset } = useAuth();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [countdown, setCountdown] = useState(3); // Countdown state
  const navigate = useNavigate();
  const oobCode = searchParams.get("oobCode");

  useEffect(() => {
    if (message) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      if (countdown === 0) {
        navigate("/login");
      }

      return () => clearInterval(interval);
    }
  }, [message, countdown, navigate]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const newPassword = data.get("newPassword");
    const confirmPassword = data.get("confirmPassword");

    if (!newPassword || !confirmPassword) {
      setError("You must enter both passwords");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      setError("");
      setMessage("");
      setLoading(true);
      console.log("oob", oobCode)
      console.log("newPassword", newPassword)
      await confirmPasswordReset(oobCode, newPassword);
      setMessage("Password has been reset successfully");
    } catch (err) {
      console.log(err);
      console.log(err.code);
      if (authErrors[err.code]) {
        setError(authErrors[err.code]);
      } else {
        setError("Failed to reset password");
      }
    }
    setLoading(false);
  }

  return (
    <div
      style={{
        height: "100dvh",
        width: "100dvw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <RoundedCard>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="assets/teldio_logo.png"
              alt="Teldio logo"
              style={{ width: "150px" }}
            />
            <Typography component="h1" variant="h5" sx={{ marginTop: "30px" }}>
              Reset Password
            </Typography>
            {error && (
              <Alert severity="error" sx={{ marginTop: "5px" }}>
                {error}
              </Alert>
            )}
            {message && (
              <Alert severity="success" sx={{ marginTop: "5px" }}>
                {message} Redirecting to sign in page in {countdown} seconds...
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3 }}
            >
              <RoundedTextField
                margin="normal"
                required
                fullWidth
                id="newPassword"
                label="New Password"
                name="newPassword"
                type="password"
                autoComplete="new-password"
                autoFocus
              />
              <RoundedTextField
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ColorButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                >
                  Reset Password
                </ColorButton>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={() => navigate("/login")}>
                  Back to Login
                </Button>
              </div>
            </Box>
          </Box>
        </Container>
      </RoundedCard>
    </div>
  );
}
