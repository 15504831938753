import React from "react";

import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../firebase/contexts/AuthContext";

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    const redirectPath = localStorage.getItem("redirectPath");
    if (redirectPath) {
      localStorage.removeItem("redirectPath");
    }

    localStorage.setItem("redirectPath", location.pathname + location.search);
    console.log("setting", location.pathname);
    console.log("set", localStorage.getItem("redirectPath"));
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
