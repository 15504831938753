import { CircularProgress, IconButton } from "@mui/material";
import React, { useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const IFrameView = ({ link, name }) => {
  const handleIconButtonClick = (event) => {
    event.stopPropagation();
    window.open(link.replace("?tools=true", ""), "_blank");
  };

  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div
      style={{
        height: "calc(100dvh - 100px)",
        overflow: "hidden",
      }}
    >
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ color: "#A0AD39", marginTop: "30px" }} />
        </div>
      )}      
      <iframe
        src={link}
        onLoad={handleLoad}
        style={{
          border: "none",
          width: "100%",
          height: "100%",
          marginTop: "0px",
          transform: "none",
        }}
        title={`page-${name}`}
      />
      <IconButton
        onClick={handleIconButtonClick}
        style={{ position: "absolute", bottom: 10, right: 10 }}
      >
        <OpenInNewIcon />
      </IconButton>
    </div>
  );
};

export default IFrameView;
