import React from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Chip, Box } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const WikiCard = ({ title, description, link, onClick, imageUrl, topic }) => {
  const handleIconButtonClick = (event) => {
    event.stopPropagation(); // Prevents the card's onClick event from firing
    window.open(link, '_blank');
  };

  return (
    <Card onClick={onClick} style={{ width: 300, cursor: 'pointer', position: 'relative' }}>
      <CardMedia
        component="img"
        height="140"
        image={imageUrl}
        alt={title}
      />
      <CardContent style={{ paddingBottom: 60 }}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        <IconButton
          onClick={handleIconButtonClick}
          style={{ position: 'absolute', bottom: 10, right: 10 }}
        >
          <OpenInNewIcon />
        </IconButton>
      </CardContent>
      <Box sx={{ position: "absolute", bottom: 10, left: 10 }}>
        <Chip label={topic} />
      </Box>
    </Card>
  );
};

export default WikiCard;
