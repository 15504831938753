// CreateBoard.js
import React, { useEffect, useState } from "react";
import { ref, push, set } from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";
import {
  TextField,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { SquaredButtonGreen, TeldioTextField } from "../../MUI_Components/Components";

const CreateBoard = ({ handleOpenFromParent, setHandleOpenFromParent }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();
  const db = getDB();

  const handleCreateBoard = () => {
    const newBoardId = push(ref(db, `/uid/${currentUser.uid}/boards`)).key;
    set(ref(db, `/uid/${currentUser.uid}/boards/${newBoardId}`), {
      title,
      description,
      columns: {},
      invitedUsers: {},
    });
    setTitle("");
    setDescription("");
    setOpen(false);
    try {
      setHandleOpenFromParent(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (handleOpenFromParent) {
      setOpen(true);
    }
  }, [handleOpenFromParent]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTitle("");
    setDescription("");
    setOpen(false);
    try {
      setHandleOpenFromParent(false);
    } catch (error) {}
  };

  return (
    <Box>
      <Tooltip title="Create New Board">
        <IconButton onClick={handleOpen}>
          <AddIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Board</DialogTitle>
        <DialogContent>
          <TeldioTextField
            autoFocus
            margin="dense"
            placeholder="Board Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TeldioTextField
            margin="dense"
            placeholder="Board Description"
            type="text"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <SquaredButtonGreen onClick={handleCreateBoard}>
            Create
          </SquaredButtonGreen>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateBoard;
