import React, { useState, useEffect } from "react";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  TextField,
  Button,
  List,
  ListItem,
  Divider,
  Stack,
  AvatarGroup,
  Tooltip,
  Avatar,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import {
  getDatabase,
  ref,
  push,
  set,
  onValue,
  remove,
} from "firebase/database";
import {
  SquaredButtonGreen,
  TeldioCheckbox,
  TeldioLinearProgress,
  TeldioRadioButton,
  TeldioTextField,
} from "../../../MUI_Components/Components";
import PollIcon from "@mui/icons-material/Poll";
import { convertToArray } from "../../../Helper/utils";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

const Poll = ({ poll, pollPath, collaborators, logAudit, taskPath }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherOption, setOtherOption] = useState("");
  const [responses, setResponses] = useState([]);
  const [hasResponded, setHasResponded] = useState(false);
  const [editingResponse, setEditingResponse] = useState(false);
  const [currentResponseId, setCurrentResponseId] = useState(null);
  const [tally, setTally] = useState();
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);

  function tallyResponses(responses) {
    const tally = {};

    responses.forEach((response) => {
      const responder = response.responder;

      response.selectedOptions.forEach((option) => {
        if (!tally[option]) {
          tally[option] = { count: 0, responders: [] };
        }
        tally[option].count++;
        tally[option].responders.push(responder);
      });

      if (response.otherOption) {
        if (!tally[response.otherOption]) {
          tally[response.otherOption] = { count: 0, responders: [] };
        }
        tally[response.otherOption].count++;
        tally[response.otherOption].responders.push(responder);
      }
    });

    return tally;
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    const db = getDatabase();
    const responsesRef = ref(db, `${pollPath}/responses`);

    onValue(responsesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const responseArray = Object.values(data);
        setResponses(responseArray);
        const userResponse = responseArray.find(
          (response) => response.responder === currentUser.uid
        );
        setHasResponded(!!userResponse);
        if (userResponse) {
          setCurrentResponseId(
            Object.keys(data).find(
              (key) => data[key].responder === currentUser.uid
            )
          );
          setSelectedOptions(userResponse.selectedOptions);
          setOtherOption(userResponse.otherOption || "");
        }
        setTally(tallyResponses(responseArray));
      }
    });
  }, [poll.uid, pollPath, currentUser.uid]);

  const handleOptionChange = (event) => {
    if (poll.allowMultiple) {
      const option = event.target.name;
      setSelectedOptions((prev) =>
        prev.includes(option)
          ? prev.filter((item) => item !== option)
          : [...prev, option]
      );
    } else {
      setSelectedOptions([event.target.value]);
    }
  };

  const handleSubmit = () => {
    const db = getDatabase();
    const responsesRef = ref(db, `${pollPath}/responses`);
    const response = {
      selectedOptions,
      otherOption: otherOption || null,
      respondedAt: Date.now(),
      responder: currentUser.uid,
    };

    logAudit(
      "Poll Vote",
      `${currentUser.displayName || currentUser.email} has been voted in ${
        poll.question
      }`
    );

    if (currentResponseId) {
      const responseRef = ref(db, `${pollPath}/responses/${currentResponseId}`);
      set(responseRef, response)
        .then(() => {
          setHasResponded(true);
          setEditingResponse(false);
        })
        .catch((error) => {
          console.error("Error updating response: ", error);
        });
    } else {
      const newResponseRef = push(responsesRef);
      set(newResponseRef, response)
        .then(() => {
          setHasResponded(true);
        })
        .catch((error) => {
          console.error("Error adding response: ", error);
        });
    }
  };

  const handleDeletePoll = () => {
    const db = getDatabase();
    const pollRef = ref(db, pollPath);

    remove(pollRef)
      .then(() => {
        console.log("Poll deleted successfully.");
      })
      .catch((error) => {
        console.error("Error deleting poll: ", error);
      });
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <PollIcon />
          <Typography variant="h6">{poll.question}</Typography>
        </Stack>
        {hasResponded && (
          <div>
            <IconButton
              onClick={() => {
                setEditingResponse(!editingResponse);
              }}
            >
              <CreateIcon />
            </IconButton>
            {currentUser.uid === poll.creator && (
              <IconButton onClick={setOpen}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
      {(!hasResponded || editingResponse) && (
        <div
          style={{
            padding: "10px",
            maxWidth: "100%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {poll.allowMultiple ? (
            poll.options.map((option, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <TeldioCheckbox
                      checked={selectedOptions.includes(option)}
                      onChange={handleOptionChange}
                      name={option}
                    />
                  }
                  label={option}
                />
              );
            })
          ) : (
            <RadioGroup
              value={selectedOptions[0] || ""}
              onChange={handleOptionChange}
            >
              {poll.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={<TeldioRadioButton />}
                  label={option}
                  value={option}
                />
              ))}
            </RadioGroup>
          )}
          {poll.allowOtherField && (
            <TeldioTextField
              fullWidth
              value={otherOption}
              onChange={(e) => setOtherOption(e.target.value)}
              placeholder="Other"
              margin="normal"
            />
          )}
          <SquaredButtonGreen
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={selectedOptions.length === 0}
          >
            Submit
          </SquaredButtonGreen>
        </div>
      )}
      {hasResponded && !editingResponse && (
        <div style={{ padding: "10px", maxWidth: "100%", margin: "auto" }}>
          {convertToArray(tally)?.map((option, index) => {
            const progress =
              (tally[option.uid]?.count / responses.length) * 100;
            return (
              <div style={{ marginBottom: "20px" }} key={index}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6">{option.uid}</Typography>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <div style={{ display: "flex", marginRight: "10px" }}>
                      <AvatarGroup max={8}>
                        {tally[option.uid]?.responders?.map((voter, index) => {
                          let user = collaborators[voter];
                          return (
                            <Tooltip
                              key={user?.email || index}
                              title={user?.displayName || user?.email}
                            >
                              <Avatar
                                src={user?.photoURL}
                                alt={user?.displayName || user?.email}
                                sx={{
                                  width: 30,
                                  height: 30,
                                  marginLeft: "10px",
                                }}
                              >
                                {user?.displayName?.substring(0, 1) ||
                                  user?.email?.substring(0, 1)}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                      </AvatarGroup>
                    </div>
                    <Typography variant="h6">
                      {parseFloat(progress.toFixed(2))}%
                    </Typography>{" "}
                  </div>
                </div>
                <TeldioLinearProgress variant="determinate" value={progress} />
              </div>
            );
          })}
          {poll?.options?.map((option, index) => {
            if (tally[option]?.count > 0) {
              return null;
            }
            const progress =
              ((tally[option]?.count || 0) / responses?.length) * 100;
            return (
              <div style={{ marginBottom: "10px" }} key={index}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6">{option}</Typography>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", marginRight: "20px" }}>
                      <AvatarGroup max={4}>
                        {tally[option]?.responders?.map((voter, index) => {
                          let user = collaborators[voter];
                          return (
                            <Tooltip
                              key={user?.email || index}
                              title={user?.name || user?.email}
                            >
                              <Avatar
                                src={user?.image}
                                alt={user?.name || user?.email}
                                sx={{
                                  width: 40,
                                  height: 40,
                                  marginLeft: "10px",
                                }}
                              >
                                {user?.name?.substring(0, 1) ||
                                  user?.email?.substring(0, 1)}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                      </AvatarGroup>
                    </div>
                    <Typography>{parseFloat(progress.toFixed(2))}%</Typography>
                  </div>
                </div>
                <TeldioLinearProgress variant="determinate" value={progress} />
              </div>
            );
          })}
        </div>
      )}
      <Typography sx={{ textAlign: "right" }}>
        Poll created by:{" "}
        {collaborators[poll.creator]?.displayName ||
          collaborators[poll.creator]?.email}
      </Typography>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "20px",
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Confirm Delete
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to delete this poll?
          </Typography>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <SquaredButtonGreen onClick={handleDeletePoll}>
              Confirm
            </SquaredButtonGreen>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Poll;
