import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
} from "@mui/material";
import { onValue, ref, update, remove, off } from "firebase/database";
import { getDB } from "../../../firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  RealDivider,
  SquaredButtonGreen,
  TeldioSwitch,
  TeldioTab,
  TeldioTabs,
  TeldioTextField,
} from "../../../MUI_Components/Components";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import PropTypes from "prop-types";
import {
  convertToArray,
  themeDetails,
  themeOptions,
} from "../../../Helper/utils";
import { useSettings } from "../../../Contexts/SettingsContext";

const colors = [
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
];

function CustomTabPanel(props) {
  const { children, value, index, backgroundColor, color, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        height: "100%",
      }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChatSidebar = ({
  open,
  onClose,
  boardId,
  currentUser,
  boardOwner,
  showingArchived,
  setShowingArchived,
  showingCardCount,
  setShowingCardCount,
  reverseComments,
  setReverseComments,
  collaborators,
}) => {
  const [title, setTitle] = useState("");
  const [background, setBackground] = useState("");
  const db = getDB();
  const storage = getStorage();
  const functions = getFunctions();
  const [loading, setLoading] = useState(true);
  const { settings } = useSettings();

  const boardPath =
    boardOwner === "own"
      ? `/uid/${currentUser.uid}/boards/${boardId}`
      : `/uid/${boardOwner}/boards/${boardId}`;

  function handleClose() {
    onClose();
  }

  return (
    <>
      {/* {loading && open && (
        <div>
          <CircularProgress sx={{ color: "#A0AD39" }} />
        </div>
      )} */}
      {open && (
        <Drawer anchor="right" open={open} onClose={handleClose}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor:
                themeDetails[settings.cardColor]?.primary || "white",
              color: themeDetails[settings.cardColor]?.font || "black",
            }}
          >
            <Typography>Hello</Typography>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default ChatSidebar;
