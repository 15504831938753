import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getDatabase,
  ref,
  push,
  update,
  onValue,
  remove,
} from "firebase/database";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Avatar,
  Grid,
  Alert as MuiAlert,
  Pagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  SquaredButtonGreen,
  TeldioTextField,
} from "../../MUI_Components/Components";

const BoardsUpdate = () => {
  const [changelogTitle, setChangelogTitle] = useState("");
  const [changelog, setChangelog] = useState("");
  const [users, setUsers] = useState([]);
  const [changelogEntries, setChangelogEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true); // Add fetching state
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [page, setPage] = useState(1);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteChangelogId, setDeleteChangelogId] = useState(null);
  const entriesPerPage = 1;

  useEffect(() => {
    const fetchUsers = async () => {
      const functions = getFunctions();
      const listUsers = httpsCallable(functions, "listUsers");
      try {
        const result = await listUsers();
        setUsers(result.data.users);
      } catch (error) {
        setErrorMessage(error.message);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const changelogRef = ref(db, "boardsChangelog");

    onValue(changelogRef, (snapshot) => {
      const changelogs = [];
      snapshot.forEach((childSnapshot) => {
        changelogs.unshift({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setChangelogEntries(changelogs);
      setFetching(false); // Set fetching to false after loading entries
    });
  }, []);

  const handleSubmit = async () => {
    if (!changelogTitle || !changelog) {
      setErrorMessage("Title and body are required");
      return;
    }
    setLoading(true);
    const db = getDatabase();
    const changelogRef = ref(db, "boardsChangelog");
    const newChangelogRef = push(changelogRef);

    const changelogData = {
      title: changelogTitle,
      text: changelog,
      timestamp: Date.now(),
      users: users.reduce((acc, user) => {
        acc[user.uid] = true; // Mark all users as needing to acknowledge
        return acc;
      }, {}),
    };

    try {
      await update(newChangelogRef, changelogData);
      setSuccess("Changelog added successfully!");
      setChangelogTitle("");
      setChangelog("");
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    const db = getDatabase();
    const changelogRef = ref(db, `boardsChangelog/${deleteChangelogId}`);
    try {
      await remove(changelogRef);
      setSuccess("Changelog deleted successfully!");
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setDeleteDialogOpen(false);
      setDeleteChangelogId(null);
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteChangelogId(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteChangelogId(null);
  };

  const handleCloseSnackbar = () => {
    setSuccess(null);
    setErrorMessage(null);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const displayedEntries = changelogEntries.slice(
    (page - 1) * entriesPerPage,
    page * entriesPerPage
  );

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Teldio Boards Changelog
      </Typography>
      <TeldioTextField
        label="Changelog Title"
        variant="outlined"
        fullWidth
        value={changelogTitle}
        onChange={(e) => setChangelogTitle(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <TeldioTextField
        label="Changelog"
        multiline
        rows={10}
        variant="outlined"
        fullWidth
        value={changelog}
        onChange={(e) => setChangelog(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <div style={{ display: "flex", justifyContent: "right" }}>
        <SquaredButtonGreen
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </SquaredButtonGreen>
      </div>
      <Divider sx={{ marginY: 3 }} />
      {fetching ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginY: 3 }}>
          <CircularProgress sx={{ color: "#A0AD39" }} />
        </Box>
      ) : (
        displayedEntries.map((entry) => {
          const acknowledgedCount = users.filter(
            (user) => !entry.users[user.uid]
          ).length;
          const totalCount = users.length;
          const acknowledgmentRatio = `${acknowledgedCount}/${totalCount}`;

          return (
            <Box key={entry.id} sx={{ marginBottom: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {entry.title}
                </Typography>
                <Tooltip title="Recall changelog">
                  <IconButton onClick={() => handleOpenDeleteDialog(entry.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography variant="body2" gutterBottom>
                Acknowledged: {acknowledgmentRatio}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Pushed: {formatTimestamp(entry.timestamp)}
              </Typography>
              <Typography variant="body2">
                {entry.text}
              </Typography>
              <Divider sx={{my: 2}} />
              <Grid container spacing={2}>
                {users.map((user) => (
                  <Grid item xs={12} sm={6} md={4} key={user.uid}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Avatar
                        alt={user.displayName || user.email}
                        src={user.photoURL}
                        sx={{ marginRight: 2 }}
                      />
                      <Typography variant="body2">
                        {user.displayName || user.email} -{" "}
                        <span
                          style={{
                            color: entry.users[user.uid] ? "grey" : "green",
                          }}
                        >
                          {entry.users[user.uid] ? "Pending" : "Acknowledged"}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          );
        })
      )}

      <Pagination
        count={Math.ceil(changelogEntries.length / entriesPerPage)}
        page={page}
        onChange={handleChangePage}
        sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
      />

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this changelog entry?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} sx={{ color: "grey" }}>
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!success || !!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {success || errorMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default BoardsUpdate;
