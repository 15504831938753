import React, { useState, useEffect, useRef } from "react";
import WikiCard from "./WikiCard";
import {
  Modal,
  Box,
  IconButton,
  Typography,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ref, onValue } from "firebase/database";
import { getDB } from "../../firebase/firebase";
import {
  RoundedTextField,
  SquaredLeftFormControl,
  SquaredLeftSelect,
  SquaredRighTextField,
  SquaredRightTextField,
} from "../../MUI_Components/Components";
import { getFunctions, httpsCallable } from "firebase/functions";

const Wiki = () => {
  const [articles, setArticles] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const functions = getFunctions();
  const getUserArticles = httpsCallable(functions, "getUserArticles");

  const db = getDB();

  const handleLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const result = await getUserArticles();
        setArticles(result.data.articles);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching user articles:", error);
        setLoading(false)
      }
    };

    fetchArticles();
    

    const topicsRef = ref(getDB(), "topics/");
    onValue(topicsRef, (snapshot) => {
      const data = snapshot.val();
      setTopics(data ? Object.values(data) : []);
    });
  }, [functions]);

  const handleOpen = (page) => {
    setSelectedPage(page);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPage(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
  };

  const filteredArticles = articles.filter((article) => {
    const matchesSearchQuery =
      article.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      article.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesTopic = selectedTopic ? article.topic === selectedTopic : true;
    return (
      !article.archived &&
      article.published &&
      matchesSearchQuery &&
      matchesTopic
    );
  });

  const articlesByTopic = topics.reduce((acc, topic) => {
    acc[topic] = filteredArticles.filter((article) => article.topic === topic);
    return acc;
  }, {});

  return (
    <div>
      <Typography variant="h2" sx={{ textAlign: "center", marginTop: "20px" }}>
        Teldio Wiki
      </Typography>
      <Box
        sx={{ display: "flex", justifyContent: "center", gap: 0, marginTop: 2 }}
      >
        <SquaredRightTextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ width: "600px" }}
        />
        <SquaredLeftFormControl variant="outlined" sx={{ width: "200px" }}>
          <InputLabel>Filter by Topic</InputLabel>
          <SquaredLeftSelect
            value={selectedTopic}
            onChange={handleTopicChange}
            label="Filter by Topic"
          >
            <MenuItem value="">
              <em>All Topics</em>
            </MenuItem>
            {topics.map((topic, index) => (
              <MenuItem key={index} value={topic}>
                {topic}
              </MenuItem>
            ))}
          </SquaredLeftSelect>
        </SquaredLeftFormControl>
      </Box>
      <Box sx={{ marginTop: "30px" }}>
        {loading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#A0AD39", marginTop: "30px" }} />
          </div>
        )}
        {Object.entries(articlesByTopic).map(
          ([topic, articles]) =>
            articles.length > 0 && (
              <Box key={topic} sx={{ marginBottom: "30px" }}>
                <Typography
                  variant="h4"
                  sx={{ marginBottom: "20px", textAlign: "center" }}
                >
                  {topic}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  {articles.map((article, index) => (
                    <WikiCard
                      key={index}
                      title={article.name}
                      description={article.description}
                      link={article.iframeLink}
                      onClick={() => handleOpen(article.iframeLink)}
                      imageUrl={article.imageLink}
                      topic={article.topic}
                    />
                  ))}
                </div>
              </Box>
            )
        )}
      </Box>

      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            left: "10%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            width: "80vw",
            height: "80vh",
            borderRadius: "8px",
            overflow: "hidden",
            border: "1px solid #ccc",
          }}
        >
          {selectedPage !== null && (
            <>
              <IconButton
                size="small"
                color="primary"
                sx={{ position: "absolute", top: 8, right: 8 }}
                onClick={() => window.open(selectedPage, "_blank")}
              >
                <OpenInNewIcon />
              </IconButton>
              {loading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress
                    sx={{ color: "#A0AD39", marginTop: "30px" }}
                  />
                </div>
              )}
              <iframe
                ref={iframeRef}
                src={selectedPage}
                onLoad={handleLoad}
                style={{
                  border: "none",
                  width: "100%",
                  height: "calc(100% - 25px)",
                  marginTop: "25px",
                  transform: "none",
                }}
                title={`page-${selectedPage}`}
              />
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Wiki;
