import React from "react";
import KeyIcon from "@mui/icons-material/Key";
import PageviewIcon from "@mui/icons-material/Pageview";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import RoomIcon from "@mui/icons-material/Room";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import CalculateIcon from "@mui/icons-material/Calculate";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { Typography } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DrawIcon from "@mui/icons-material/Draw";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LaptopIcon from "@mui/icons-material/Laptop";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CloudIcon from '@mui/icons-material/Cloud';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

const RecentTool = (props) => {
  function handleIconClick() {
    const { path, link, name } = props.toolData || {};
    if (props.tool === "Internal Clipboard") {
      props.handleToolSelect("Clipboard", null, "/clipboard", "", "Clipboard");
      return;
    }
    props.handleToolSelect(props.tool, null, path, link, name);
  }

  return (
    <div onClick={handleIconClick} style={{ cursor: "pointer" }}>
      <div
        style={{
          backgroundColor: "white",
          marginTop: "50px",
          marginLeft: "10px",
          marginRight: "10px",
          width: "100px",
          height: "100px",
          borderRadius: "30px",
          boxShadow: "15px 15px 19px rgba(1, 1, 1, 0.1)",
          padding: "20px 20px 40px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {props.tool === "FileUpload" && (
          <DriveFolderUploadIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "FileUpload Viewer" && (
          <PageviewIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "GPS Configurator" && (
          <RoomIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "Config Merger" && (
          <CallMergeIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "Beacon Calculator" && (
          <CalculateIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "Trufleet Adder" && (
          <AddLocationIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {(props.tool === "Clipboard" ||
          props.tool === "Internal Clipboard") && (
          <ContentPasteIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "TEG Licenser" && (
          <KeyIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "Trufleet Plus Demos" && (
          <PlayCircleIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "Wiki" && (
          <MenuBookIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "Wiki Manager" && (
          <DrawIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "Admin Console" && (
          <AdminPanelSettingsIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "TEG Viewer" && (
          <LaptopIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "Boards" && (
          <DashboardIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "NOAA" && (
          <CloudIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
        {props.tool === "Boards Update" && (
          <DashboardCustomizeIcon sx={{ fontSize: 100, color: "grey" }} />
        )}
      </div>
      <Typography
        variant="subtitle2"
        sx={{ color: "grey", textAlign: "center", marginTop: "20px" }}
      >
        {props.tool}
      </Typography>
    </div>
  );
};

export default RecentTool;
