export function getRoleReadable(role) {
  let roles = {
    admin: "Admin",
    avtec: "Avtec",
    teldioSupport: "Teldio Support",
    teldioSales: "Teldio Sales",
    dev: "Dev",
  };
  return roles[role] || undefined;
}

export function getRoles() {
  let roles = [
    { value: "default", label: "Default" },
    { value: "avtec", label: "Avtec" },
    { value: "teldioSales", label: "Teldio Sales" },
    { value: "teldioSupport", label: "Teldio Support" },
    { value: "admin", label: "Admin" },
    { value: "dev", label: "Dev" },
  ];
  return roles;
}

export const themeOptions = [
  { color: "white", label: "White", title: "white" },
  { color: "#333", label: "Dark Shade", title: "dark" },
  { color: "#f5f5dc", label: "Bone Cream", title: "cream" },
];

export const themeDetails = {
  white: {
    primary: "white",
    secondary: "#F1F2F4",
    font: "black",
    alt: "#E3E6EA",
  },
  dark: {
    primary: "#333",
    secondary: "#22272B",
    font: "white",
    alt: "#636363",
  },
  cream: {
    primary: "#F9F6EE",
    secondary: "#ede9da",
    font: "black",
    alt: "white",
  },
};

export const getLuminance = (hex) => {
  const rgb = parseInt(hex.substring(1), 16); // Convert hex to RGB
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  // Calculate luminance
  return 0.299 * r + 0.587 * g + 0.114 * b;
};

export const getTextColor = (hex) =>
  getLuminance(hex) > 150 ? "#000000" : "#FFFFFF"; // Threshold value for luminance

export function convertToArray(data) {
  if (!data) {
    return;
  }
  return Object.entries(data).map(([uid, value]) => ({
    uid,
    ...value,
  }));
}

export const sendEmail = async (email, template, variables) => {
  try {
    const response = await fetch(
      "https://us-central1-teldiotools.cloudfunctions.net/sendEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          template: template,
          variables: variables,
        }),
      }
    );

    const result = await response.json();
    console.log("Email sent successfully:", result);
  } catch (error) {
    console.error("Error sending email:", error);
  }
};

export const colors = [
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
];

export function agnosticCommandEnter(e) {
  const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
  const isEnter = e.code === "Enter";
  const isCmdOrCtrl = (isMac && e.metaKey) || (!isMac && e.ctrlKey);
  return isEnter && isCmdOrCtrl;
}

export function formatDate(date) {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  return `${minutes}:${hours} ${day}/${month}/${year}`;
}
