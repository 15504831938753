import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  IconButton,
  Modal,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
  Divider,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  SquaredButtonGreen,
  TeldioTextField,
} from "../../../MUI_Components/Components";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import { getDB } from "../../../firebase/firebase";
import { ref, get, set } from "firebase/database";
import { agnosticCommandEnter, themeDetails } from "../../../Helper/utils";
import { useSettings } from "../../../Contexts/SettingsContext";

const Links = ({
  collaborators,
  comments,
  setComments,
  boardId,
  columnId,
  taskId,
  boardOwner,
  logAudit,
  taskName,
  taskPath,
}) => {
  const { currentUser } = useAuth();
  const [links, setLinks] = useState([]);
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [shortcut, setShortcut] = useState("");
  const [url, setUrl] = useState("");
  const db = getDB();
  const [error, setError] = useState("");
  const { settings } = useSettings();

  const linksPath = `${taskPath}/links`;

  useEffect(() => {
    const fetchLinks = async () => {
      const taskRef = ref(db, linksPath);
      const snapshot = await get(taskRef);
      const linksData = snapshot.val() || [];
      setLinks(linksData);
    };

    fetchLinks();
  }, [linksPath]);

  function handleClose() {
    setError("");
    setOpen(false);
  }

  const handleAdd = () => {
    setEditIndex(null);
    setDeleteIndex(null);
    setShortcut("");
    setUrl("");
    setOpen(true);
  };

  const handleEdit = (e, index) => {
    e.stopPropagation();
    setEditIndex(index);
    setShortcut(links[index].shortcut);
    setUrl(links[index].url);
    setOpen(true);
  };

  const validateAndFormatUrl = (url) => {
    let formattedUrl = url;
    if (!/^https?:\/\//i.test(formattedUrl)) {
      formattedUrl = "https://" + formattedUrl;
    }
    try {
      new URL(formattedUrl);
      return formattedUrl;
    } catch (e) {
      return "";
    }
  };

  const handleSave = async () => {
    const formattedUrl = validateAndFormatUrl(url);
    if (formattedUrl === "") {
      setError("You must enter a valid URL");
      return;
    }

    const newLinks = [...links];
    if (editIndex !== null) {
      newLinks[editIndex] = { shortcut, url: formattedUrl };
    } else {
      newLinks.push({ shortcut, url: formattedUrl });
    }

    setLinks(newLinks);
    setOpen(false);
    setError("");
    logAudit(
      "Link Added",
      `${
        currentUser.displayName || currentUser.email
      } has added a link ${shortcut} to card ${taskName}`,
      `has added a link ${shortcut}`
    );
    const db = getDB();
    const taskRef = ref(db, linksPath);
    await set(taskRef, newLinks);
  };
  const handleDelete = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const confirmDelete = async () => {
    const newLinks = links.filter((_, i) => i !== deleteIndex);
    setLinks(newLinks);
    setOpen(false);
    setDeleteIndex(null);

    const db = getDB();
    const taskRef = ref(db, linksPath);
    await set(taskRef, newLinks);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <LinkIcon />
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Links
        </Typography>
      </Stack>

      <List>
        {links.map((link, index) => (
          <>
            {index !== 0 && <Divider />}
            <ListItem
              key={index}
              component="a"
              href={link.url}
              target="_blank"
              sx={{
                textDecoration: "none",
                color: "#5dbcc2",
                textTransform: "uppercase",
                "&:hover": {
                  textDecoration: "underline", // This adds underline on hover
                },
              }}
            >
              <ListItemText primary={link.shortcut || link.url} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={(e) => handleEdit(e, index)}
                  sx={{ marginRight: "1px" }}
                >
                  <EditIcon
                    sx={{
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                  />
                </IconButton>
                <IconButton edge="end" onClick={() => handleDelete(index)}>
                  <DeleteIcon
                    sx={{
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </>
        ))}
      </List>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <SquaredButtonGreen sx={{ width: "100px" }} onClick={handleAdd}>
          Add
        </SquaredButtonGreen>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "20px",
            p: 4,
          }}
        >
          {deleteIndex !== null ? (
            <>
              <Typography variant="h6" component="h2">
                Confirm Delete
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Are you sure you want to delete this link?
              </Typography>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <SquaredButtonGreen onClick={confirmDelete}>
                  Confirm
                </SquaredButtonGreen>
              </div>
            </>
          ) : (
            <>
              <Typography variant="h6" component="h2">
                {editIndex !== null ? "Edit Link" : "Add Link"}
              </Typography>
              {error && (
                <Typography variant="subtitle" color="error">
                  {error}
                </Typography>
              )}
              <TeldioTextField
                fullWidth
                margin="normal"
                label="Shortcut"
                value={shortcut}
                onChange={(e) => setShortcut(e.target.value)}
              />
              <TeldioTextField
                fullWidth
                margin="normal"
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required
                onKeyDown={(e) => {
                  if (agnosticCommandEnter(e)) {
                    handleSave();
                  }
                }}
              />
              <div style={{ display: "flex", justifyContent: "right" }}>
                <SquaredButtonGreen
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </SquaredButtonGreen>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Links;
