import React, { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  IconButton,
  Modal,
  Tooltip,
  Snackbar,
  Alert as MuiAlert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
} from "@mui/material";
import { Delete, LockReset, Add } from "@mui/icons-material";
import {
  SquaredButtonGreen,
  TeldioLinearProgress,
} from "../../MUI_Components/Components";
import { getAuth } from "firebase/auth";
import PasswordIcon from "@mui/icons-material/Password";
import { formatDistanceToNow } from "date-fns";
import { getRoles } from "../../Helper/utils";

const AdminConsole = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ email: "", password: "", role: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [resetPasswordValue, setResetPasswordValue] = useState("");
  const [userToResetPassword, setUserToResetPassword] = useState(null);
  const [loadingRoleChange, setLoadingRoleChange] = useState(false);
  const roles = getRoles();


  useEffect(() => {
    const auth = getAuth();
    setCurrentUserUid(auth.currentUser?.uid);

    const fetchUsers = async () => {
      const functions = getFunctions();
      const listUsers = httpsCallable(functions, "listUsers");
      try {
        const result = await listUsers();
        setUsers(result.data.users);
        setLoading(false);
      } catch (error) {
        setErrorMessage(error.message);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  function generatePassword(length) {
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const allChars = lowercase + uppercase + numbers;

    let password = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      password += allChars[randomIndex];
    }

    return password;
  }

  const handleRoleChange = async (uid, newRole) => {
    const functions = getFunctions();
    const setUserRole = httpsCallable(functions, "setUserRole");
    setLoadingRoleChange(uid);
    try {
      await setUserRole({
        email: users.find((user) => user.uid === uid).email,
        role: newRole,
      });
      setUsers(
        users.map((user) =>
          user.uid === uid ? { ...user, role: newRole } : user
        )
      );
      setSuccess("Role updated successfully!");
      setLoadingRoleChange(false);
    } catch (error) {
      setLoadingRoleChange(false);
      console.log("Error updating role:", error); // Log error
      setErrorMessage(error.message);
    }
  };

  const handleAddUser = async (e, password) => {
    e.preventDefault();
    setAddUserLoading(true);
    const functions = getFunctions();
    const createUser = httpsCallable(functions, "createUser");
    const finalUser = ({ ...newUser, password: generatePassword(12) });

    try {
      await createUser(finalUser);
      setUsers([...users, finalUser]);
      setNewUser({ email: "", password: "", role: "" });
      setModalOpen(false);
      setSuccess("User added successfully!");
    } catch (error) {
      console.log("Error adding user:", error); // Log error
      setErrorMessage(error.message);
    } finally {
      setAddUserLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    setDeleteLoading(true);
    const functions = getFunctions();
    const deleteUser = httpsCallable(functions, "deleteUser");

    try {
      await deleteUser({ uid: userToDelete.uid });
      setUsers(users.filter((user) => user.uid !== userToDelete.uid));
      setSuccess("User deleted successfully!");
    } catch (error) {
      console.log("Error deleting user:", error); // Log error
      setErrorMessage(error.message);
    } finally {
      setDeleteLoading(false);
      setConfirmDeleteOpen(false);
    }
  };

  const handleSendPasswordReset = async (email) => {
    setResetLoading(true);
    const functions = getFunctions();
    const sendPasswordReset = httpsCallable(functions, "sendPasswordReset");

    try {
      await sendPasswordReset({ email });
      setSuccess("Password reset email sent successfully!");
    } catch (error) {
      console.log("Error sending password reset email:", error); // Log error
      setErrorMessage(error.message);
    } finally {
      setResetLoading(false);
    }
  };

  const handleManualPasswordReset = async () => {
    const functions = getFunctions();
    const resetPassword = httpsCallable(functions, "resetPassword");

    try {
      console.log("resetpass", resetPasswordValue);
      await resetPassword({
        uid: userToResetPassword.uid,
        newPassword: resetPasswordValue,
      });
      setSuccess("Password reset successfully!");
      setResetPasswordModalOpen(false);
      setResetPasswordValue("");
      setUserToResetPassword(null);
    } catch (error) {
      console.log("Error resetting password:", error); // Log error
      setErrorMessage(error.message);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(null);
    setErrorMessage(null);
  };

  const handleOpenConfirmDelete = (user) => {
    setUserToDelete(user);
    setConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDelete = () => {
    setUserToDelete(null);
    setConfirmDeleteOpen(false);
  };

  const handleOpenResetPasswordModal = (user) => {
    setUserToResetPassword(user);
    setResetPasswordModalOpen(true);
  };

  const handleCloseResetPasswordModal = () => {
    setUserToResetPassword(null);
    setResetPasswordValue("");
    setResetPasswordModalOpen(false);
  };

  const formatLastLoginTime = (lastLoginAt) => {
    if (!lastLoginAt) return "N/A";
    const lastLoginDate = new Date(lastLoginAt);
    return formatDistanceToNow(lastLoginDate, { addSuffix: true });
  };

  const formatExactLoginTime = (lastLoginAt) => {
    if (!lastLoginAt) return "N/A";
    const lastLoginDate = new Date(lastLoginAt);
    return lastLoginDate.toLocaleString();
  };

  if (loading)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress sx={{ color: "#A0AD39", marginTop: "30px" }} />
      </div>
    );
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ padding: 4 }}>
      <Container maxWidth={1000}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{
              textAlign: "center",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            Admin Console
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "auto",
              marginLeft: "auto", // Pushes the button to the far right
            }}
          >
            <SquaredButtonGreen
              variant="contained"
              onClick={() => setModalOpen(true)}
              endIcon={<Add />}
              aria-label="add user"
            >
              Add User
            </SquaredButtonGreen>
          </Box>
        </Box>
      </Container>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add New User
          </Typography>
          <Box component="form" onSubmit={handleAddUser}>
            <TextField
              label="Email"
              type="email"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              required
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            {/* <TextField
              label="Password"
              type="password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
              required
              fullWidth
              sx={{ marginBottom: 2 }}
            /> */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Role</InputLabel>
              <Select
                value={newUser.role}
                onChange={(e) =>
                  setNewUser({ ...newUser, role: e.target.value })
                }
                required
              >
                {roles.map((role) => {
                  return <MenuItem value={role.value}>{role.label}</MenuItem>;
                })}
                {/* <MenuItem value="">None</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="avtec">Avtec</MenuItem>
                <MenuItem value="teldioSales">Teldio Sales</MenuItem>
                <MenuItem value="teldioSupport">Teldio Support</MenuItem>
                <MenuItem value="dev">Dev</MenuItem> */}
              </Select>
            </FormControl>
            <SquaredButtonGreen
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={addUserLoading}
            >
              {addUserLoading ? <CircularProgress size={24} /> : "Add User"}
            </SquaredButtonGreen>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={resetPasswordModalOpen}
        onClose={handleCloseResetPasswordModal}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Reset User Password
          </Typography>
          <TextField
            label="New Password"
            type="password"
            value={resetPasswordValue}
            onChange={(e) => setResetPasswordValue(e.target.value)}
            required
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <SquaredButtonGreen
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleManualPasswordReset}
          >
            Reset Password
          </SquaredButtonGreen>
        </Box>
      </Modal>

      <Dialog open={confirmDeleteOpen} onClose={handleCloseConfirmDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {userToDelete?.email}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            variant="contained"
            color="error"
            disabled={deleteLoading}
          >
            {deleteLoading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper} sx={{ marginTop: 4, maxWidth: 1000 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Last Logged In</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.uid}>
                  <TableCell sx={{ height: "56px" }}>{user.email}</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      {loadingRoleChange === user.uid ? (
                        <TeldioLinearProgress sx={{ width: "206px" }} />
                      ) : (
                        <Select
                          value={user.role || ""}
                          onChange={(e) =>
                            handleRoleChange(
                              user.uid,
                              e.target.value.toString()
                            )
                          }
                          disabled={user.uid === currentUserUid}
                        >
                          {roles.map((role) => {
                            return (
                              <MenuItem value={role.value}>
                                {role.label}
                              </MenuItem>
                            );
                          })}
                          {/* <MenuItem value="">None</MenuItem>
                          <MenuItem value="admin">Admin</MenuItem>
                          <MenuItem value="avtec">Avtec</MenuItem>
                          <MenuItem value="teldioSales">Teldio Sales</MenuItem>
                          <MenuItem value="teldioSupport">
                            Teldio Support
                          </MenuItem>
                          <MenuItem value="dev">Dev</MenuItem> */}
                        </Select>
                      )}
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={formatExactLoginTime(user.lastLoginAt)}>
                      <span style={{ cursor: "pointer" }}>
                        {formatLastLoginTime(user.lastLoginAt)}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Send Reset Password">
                      <span style={{ marginRight: "10px" }}>
                        <IconButton
                          onClick={() => handleSendPasswordReset(user.email)}
                          disabled={user.uid === currentUserUid || resetLoading}
                          size="small"
                        >
                          {resetLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <LockReset />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Reset Password Manually">
                      <span style={{ marginRight: "10px" }}>
                        <IconButton
                          onClick={() => handleOpenResetPasswordModal(user)}
                          disabled={user.uid === currentUserUid || resetLoading}
                          size="small"
                        >
                          {resetLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <PasswordIcon />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Delete User">
                      <span>
                        <IconButton
                          onClick={() => handleOpenConfirmDelete(user)}
                          disabled={
                            user.uid === currentUserUid || deleteLoading
                          }
                          size="small"
                        >
                          {deleteLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <Delete />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Snackbar
        open={!!success || !!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {success || errorMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default AdminConsole;
