import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import RecentTool from "./RecentTool";
import { useAuth } from "../../firebase/contexts/AuthContext";
import { useSettings } from "../../Contexts/SettingsContext";
import teldioIcon from "../../Icons/favicon.ico";
const DashboardPage = ({ handleToolSelect }) => {
  const { settings } = useSettings();
  const [recentTools, setRecentTools] = useState();
  const { currentUser } = useAuth();

  useEffect(() => {
    document.title = `Teldio Tools`;
    setRecentTools(settings?.recent || []);
  }, [settings]);

  useEffect(() => {
    function changeIcon() {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = teldioIcon;
    }
    changeIcon();
  }, []);

  const toolData = {
    FileUpload: {
      path: "/file-upload",
      link: `https://fileupload.teldio.com?tools=true&email=${currentUser.email}`,
      name: "File Upload",
    },
    "FileUpload Viewer": {
      path: "/file-upload-viewer",
      link: "",
      name: "FileUpload Viewer",
    },
    "GPS Configurator": {
      path: "/gps-configurator",
      link: "https://gpsconfigurator.teldio.com?tools=true",
      name: "GPS Configurator",
    },
    "Config Merger": {
      path: "/config-merger",
      link: "https://configmerger.teldio.com?tools=true",
      name: "Config Merger",
    },
    "Beacon Calculator": {
      path: "/beacon-calculator",
      link: "https://beaconcalculator.teldio.com?tools=true",
      name: "Beacon Calculator",
    },
    "Trufleet Adder": {
      path: "/trufleet-adder",
      link: "https://adder.teldio.com?tools=true",
      name: "Trufleet Adder",
    },
    Clipboard: { path: "/clipboard", link: "", name: "Clipboard" },
    "TEG Licenser": {
      path: "",
      link: "http://licenser.teldio.com/",
      name: "TEG Licenser",
    },
    "Admin Console": {
      path: "/admin-console",
      link: "",
      name: "Admin Console",
    },
    "Trufleet Plus Demos": {
      path: "/trufleet-plus-demos",
      link: "",
      name: "Trufleet Plus Demos",
    },
    Wiki: { path: "/wiki", link: "", name: "Wiki" },
    "Wiki Manager": { path: "/wiki-manager", link: "", name: "Wiki Manager" },
    "TEG Viewer": { path: "/teg-viewer", link: "", name: "TEG Viewer" },
    Boards: { path: "/boards", link: "", name: "Boards" },
    NOAA: {
      path: "/NOAA",
      link: `https://noaa.teldio.com?tools=true`,
      name: "NOAA Locator",
    },
    "Boards Update": {
      path: "/boards-update",
      link: "",
      name: "Boards Update",
    },
  };

  return (
    <div className="container">
      <div
        style={{
          backgroundColor: "white",
          marginTop: "50px",
          width: "80dvw",
          borderRadius: "30px",
          boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
          padding: "20px 20px 40px 20px",
        }}
      >
        <div className="bannerContainer">
          <img
            src={"assets/teldio_logo.png"}
            alt="Teldio Logo"
            className="logo"
          />
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "60px",
            marginBottom: "70px",
          }}
        >
          <Typography variant="h4">Welcome to Teldio Tools Hub!</Typography>
          <Typography variant="h6">
            The one stop shop for all Teldio tools
          </Typography>
          <Typography variant="subtitle1">
            (except the one you probably need)
          </Typography>
        </div>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Recent Tools
        </Typography>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          {recentTools?.length === 0 && (
            <Typography variant="subtitle1" marginTop={"20px"}>
              Go Explore! I promise there will be recent tools when you come
              back..
            </Typography>
          )}
          {recentTools?.map((tool, index) => (
            <RecentTool
              key={index}
              tool={tool}
              toolData={toolData[tool]}
              handleToolSelect={handleToolSelect}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
