import React, { useState, useEffect } from "react";
import {
  ref,
  onValue,
  set,
  push,
  update,
  remove,
  onDisconnect,
  off,
  get,
} from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";
import Column from "./Column";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Paper,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Modal,
  Divider,
  Avatar,
  Tooltip,
  AvatarGroup,
  Badge,
  Popover,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Add as AddIcon,
  Settings as SettingsIcon,
  Label as LabelIcon,
  Close as CloseIcon,
  Group as GroupIcon,
  Celebration as CelebrationIcon,
  Campaign as CampaignIcon,
  MoreVert as MoreVertIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  ActiveBadge,
  SquaredButtonGreen,
  TeldioBadge,
  TeldioTextField,
} from "../../MUI_Components/Components";
import { lighten } from "@mui/system";
import {
  colors,
  convertToArray,
  getTextColor,
  themeDetails,
} from "../../Helper/utils";
import SettingsSidebar from "./SettingsSidebar";
import InviteSidebar from "./InviteSidebar";
import { getFunctions, httpsCallable } from "firebase/functions";
import { formatDistanceToNow } from "date-fns";
import { useParams } from "react-router-dom";
import { useSettings } from "../../Contexts/SettingsContext";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterPanel from "./BoardsComponents/FilterPanel";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ChatSidebar from "./BoardsComponents/ChatSidebar";
import SearchIcon from "@mui/icons-material/Search";

const Board = ({
  isSidebarOpen,
  boardOwner,
  notificationDrawerOpen,
  setNotificationDrawerOpen,
  ownBoards,
  collaboratedBoards,
}) => {
  const { boardId } = useParams(); // Use useParams to get boardId
  const [board, setBoard] = useState({ columns: {}, columnsOrder: [] });
  const [addingColumn, setAddingColumn] = useState(false);
  const [newColumnTitle, setNewColumnTitle] = useState("");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [labelsOpen, setLabelsOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [labels, setLabels] = useState([]);
  const [newLabelName, setNewLabelName] = useState("");
  const [newLabelColor, setNewLabelColor] = useState("");
  const [editingLabel, setEditingLabel] = useState(null);
  const { currentUser } = useAuth();
  const [viewers, setViewers] = useState([]);
  const [collaborators, setCollaborators] = useState({});
  const [activity, setActivity] = useState([]);
  const [openTask, setOpenTask] = useState({ columnId: null, taskId: null });
  const defaultBackground = "#F4F4F4";
  const [showingArchived, setShowingArchived] = useState();
  const [showingCardCount, setShowingCardCount] = useState();
  const [reverseComments, setReverseComments] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [filteredLabels, setFilteredLabels] = useState([]);
  const { settings } = useSettings();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchBarText, setSearchBarText] = useState("");
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "f") {
        event.preventDefault();
        setShowSearchBar((prev) => !prev); // Use functional update to get the latest state
        setSearchBarText("");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // No need to include showSearchBar in the dependency array

  const logAuditForWatchers = (watchers, columnId, taskId, message) => {
    watchers.forEach((watcher) => {
      logAudit(
        columnId,
        taskId,
        "Column Watcher",
        message,
        watcher,
        "Column Watcher"
      );
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleOpenNotificationDrawer = () => setNotificationDrawerOpen(true);
  const handleCloseNotificationDrawer = () => setNotificationDrawerOpen(false);

  useEffect(() => {
    const fetchCollaborators = async () => {
      if (!currentUser || !boardOwner) return;
      const functions = getFunctions();
      const getCollaborators = httpsCallable(functions, "getCollaborators");
      try {
        let uidOfOwner = boardOwner === "own" ? currentUser.uid : boardOwner;
        const result = await getCollaborators({
          boardId,
          boardOwner: uidOfOwner,
        });
        setCollaborators(result.data);
      } catch (error) {
        console.error("Error fetching collaborators:", error);
      }
    };
    fetchCollaborators();
  }, [boardId, boardOwner, currentUser]);

  useEffect(() => {
    if (board.viewers) {
      setViewers(Object.values(board.viewers));
    }
  }, [board.viewers]);

  const db = getDB();
  const boardPath =
    boardOwner === "own"
      ? `/uid/${currentUser.uid}/boards/${boardId}`
      : `/uid/${boardOwner}/boards/${boardId}`;

  function handleClearNotification(activityID) {
    const viewersRef = ref(
      db,
      `${boardPath}/activity/${activityID}/acknowledged`
    );

    set(viewersRef, {
      acknowledged: true,
    });
  }

  async function logToColumn(details, columnId) {
    console.log("logging", details, "ID:", columnId);
    // Log the audit entry
    const auditRef = push(ref(db, `${boardPath}/columns/${columnId}/audit`));
    const newAudit = {
      action: "Column Watcher",
      details: details,
      user: currentUser.uid,
      timestamp: Date.now(),
      processed: false,
      sender: currentUser.displayName || currentUser.email,
      senderUID: currentUser.uid,
    };
    await set(auditRef, newAudit);
  }

  const logAudit = async (
    column,
    task,
    action,
    details,
    title,
    relativeDetails
  ) => {
    const auditRef = ref(
      db,
      `${boardPath}/columns/${column}/tasks/${task}/audit`
    );
    const newAuditEntry = {
      action,
      details,
      timestamp: Date.now(),
      sender: currentUser.displayName || currentUser.email,
      senderUID: currentUser.uid,
      boardName: board?.title,
      boardId: boardId,
      title: title,
      relativeDetails: relativeDetails || null,
    };
    await push(auditRef, newAuditEntry);
  };

  function handleDeleteNotification(e, incident) {
    e.stopPropagation();
    console.log(incident);
    remove(ref(db, `${boardPath}/activity/${incident.uid}`));
  }

  useEffect(() => {
    if (!board.activity) {
      return;
    }
    console.log(convertToArray(board.activity));
    const activityRes = convertToArray(board?.activity)
      .filter((user) => user.mentionedUser === currentUser.email)
      .map((incident) => {
        if (!incident.columnId || !incident.taskId) {
          const columnId = findColumnByTaskId(incident.taskId);
          return { ...incident, columnId };
        }
        return incident;
      })
      .reverse();
    setActivity(activityRes);
  }, [board, currentUser.email]);

  useEffect(() => {
    const boardRef = ref(db, boardPath);
    if (boardPath.includes("null")) {
      return;
    }
    const viewersRef = ref(db, `${boardPath}/viewers/${currentUser.uid}`);

    set(viewersRef, {
      uid: currentUser.uid,
      name: currentUser.displayName || currentUser.email,
      image: currentUser.photoURL,
    });

    onDisconnect(viewersRef).remove();

    const unsubscribe = onValue(boardRef, (snapshot) => {
      const boardData = snapshot.val();
      if (boardData) {
        document.title = `${boardData.title} | Teldio Tools`;
        setBoard({
          ...boardData,
          columns: boardData.columns || {},
          columnsOrder:
            boardData.columnsOrder || Object.keys(boardData.columns || {}),
        });
      } else {
        setBoard({ columns: {}, columnsOrder: [] });
      }
    });

    const labelsRef = ref(db, `${boardPath}/labels`);
    onValue(labelsRef, (snapshot) => setLabels(snapshot.val() || []));

    return () => {
      remove(viewersRef);
      unsubscribe();
    };
  }, [boardId, currentUser.uid, db, boardOwner, boardPath]);

  const handleAddColumn = () => {
    if (newColumnTitle.trim() && newColumnTitle.trim().length < 30) {
      const newColumnId = push(ref(db, `${boardPath}/columns`)).key;
      set(ref(db, `${boardPath}/columns/${newColumnId}`), {
        title: newColumnTitle,
        tasks: {},
        taskOrder: [],
      });
      const updatedColumnsOrder = [...board.columnsOrder, newColumnId];
      set(ref(db, `${boardPath}/columnsOrder`), updatedColumnsOrder);
      setNewColumnTitle("");
      setAddingColumn(false);
    }
  };

  const handleCancelAddColumn = () => {
    setNewColumnTitle("");
    setAddingColumn(false);
  };

  const onDragEnd = async (result) => {
    const { source, destination, type } = result;
    if (!destination) return;

    if (type === "COLUMN") {
      const newColumnsOrder = Array.from(board.columnsOrder);
      const [removed] = newColumnsOrder.splice(source.index, 1);
      newColumnsOrder.splice(destination.index, 0, removed);
      setBoard((prev) => ({ ...prev, columnsOrder: newColumnsOrder }));
      update(ref(db, boardPath), { columnsOrder: newColumnsOrder });
    } else if (type === "TASK") {
      const startCol = board.columns[source.droppableId];
      const finishCol = board.columns[destination.droppableId];

      if (!startCol || !finishCol) return;

      console.log("first");

      if (startCol === finishCol) {
        // Moving within the same column
        const newTaskOrder = Array.from(startCol.taskOrder);
        const [movedTask] = newTaskOrder.splice(source.index, 1);
        newTaskOrder.splice(destination.index, 0, movedTask);

        setBoard((prev) => ({
          ...prev,
          columns: {
            ...prev.columns,
            [source.droppableId]: { ...startCol, taskOrder: newTaskOrder },
          },
        }));

        update(ref(db, `${boardPath}/columns/${source.droppableId}`), {
          taskOrder: newTaskOrder,
        });
      } else {
        // Moving between different columns
        const startTaskOrder = Array.from(startCol.taskOrder || []);
        const finishTaskOrder = Array.from(finishCol.taskOrder || []);

        const [movedTask] = startTaskOrder.splice(source.index, 1);
        finishTaskOrder.splice(destination.index, 0, movedTask);

        setBoard((prev) => ({
          ...prev,
          columns: {
            ...prev.columns,
            [source.droppableId]: { ...startCol, taskOrder: startTaskOrder },
            [destination.droppableId]: {
              ...finishCol,
              taskOrder: finishTaskOrder,
            },
          },
        }));

        update(ref(db, `${boardPath}/columns/${source.droppableId}`), {
          taskOrder: startTaskOrder,
        });
        update(ref(db, `${boardPath}/columns/${destination.droppableId}`), {
          taskOrder: finishTaskOrder,
        });

        const movedTaskData = startCol.tasks ? startCol.tasks[movedTask] : null;

        if (movedTaskData) {
          set(
            ref(
              db,
              `${boardPath}/columns/${destination.droppableId}/tasks/${movedTask}`
            ),
            { ...movedTaskData, columnId: destination.droppableId }
          )
            .then(() =>
              remove(
                ref(
                  db,
                  `${boardPath}/columns/${source.droppableId}/tasks/${movedTask}`
                )
              )
            )
            .then(() => {
              logAudit(
                destination.droppableId,
                movedTask,
                "Card Moved",
                `${movedTaskData.title} moved from column ${startCol.title} to ${finishCol.title}`,
                "Watched Card Moved",
                `moved this from <bold>${startCol.title}</bold> to <bold>${finishCol.title}</bold>`
              );
              logToColumn(
                `${movedTaskData.title} moved into column ${finishCol.title} from ${startCol.title}`,
                destination.droppableId
              );
              logToColumn(
                `${movedTaskData.title} moved out of column ${startCol.title} to ${finishCol.title}`,
                source.droppableId
              );
            });
        }
      }
    }
  };

  const handleAddLabel = () => {
    if (newLabelName.trim() && newLabelColor) {
      const newLabelId = push(ref(db, `${boardPath}/labels`)).key;
      set(ref(db, `${boardPath}/labels/${newLabelId}`), {
        name: newLabelName,
        color: newLabelColor,
      });
      setNewLabelName("");
      setNewLabelColor("");
      setEditingLabel(null);
    }
  };

  const handleLabelClick = (labelId) =>
    setEditingLabel({ id: labelId, ...labels[labelId] });

  const handleUpdateLabel = () => {
    if (editingLabel) {
      const { id, name, color } = editingLabel;
      update(ref(db, `${boardPath}/labels/${id}`), { name, color });
      setEditingLabel(null);
    }
  };

  const handleDeleteLabel = () => {
    if (editingLabel) {
      const { id } = editingLabel;
      remove(ref(db, `${boardPath}/labels/${id}`));
      setEditingLabel(null);
    }
  };

  const handleCloseLabelModal = () => {
    setNewLabelName("");
    setNewLabelColor("");
    setEditingLabel(null);
  };

  const findColumnByTaskId = (taskId) => {
    for (const columnId in board.columns) {
      const column = board.columns[columnId];
      if (column.tasks && column.tasks[taskId]) {
        return columnId;
      }
    }
    return null;
  };

  const getDisplayName = (userId) => {
    const user = collaborators[userId];
    return user?.displayName || user?.email.split("@")[0];
  };

  const getTaskTitle = (taskId) => {
    if (!board?.columns) return "Deleted Task";
    for (let column in board.columns) {
      if (board.columns[column]?.tasks?.[taskId]) {
        return board.columns[column].tasks[taskId]?.title || "Deleted Task";
      }
    }
    return "Deleted Task";
  };

  useEffect(() => {
    const boardRef = ref(db, boardPath);
    const listener = onValue(boardRef, (snapshot) => {
      const boardData = snapshot.val();
      if (boardData) {
        if (boardOwner === "own") {
          const members = boardData?.settings?.filteredMembers || [];
          setFilteredMembers(members);
          const labels = boardData?.settings?.filteredLabels || [];
          setFilteredLabels(labels);
        } else {
          const members =
            boardData?.collaborators?.[currentUser?.uid]?.filteredMembers || [];
          setFilteredMembers(members);
          const labels =
            boardData?.collaborators?.[currentUser?.uid]?.filteredLabels || [];
          setFilteredLabels(labels);
        }
      }
    });

    return () => {
      off(boardRef, listener);
    };
  }, [boardPath, db, boardOwner, currentUser]);

  if (!board) return <div>Loading...</div>;

  return (
    <Box
      sx={{
        backgroundColor: board?.background?.startsWith("http")
          ? "transparent"
          : board?.background || defaultBackground,
        backgroundImage: board?.background?.startsWith("http")
          ? `url(${board?.background})`
          : "none",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
        overflow: "hidden", // Ensure container doesn't scroll
      }}
    >
      <div
        style={{
          marginLeft: isSidebarOpen ? 240 : 45,
          transition: "width 0.3s",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingY: "10px",
            marginBottom: "20px",
            background: "rgba(36, 34, 34, 0.3)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(2px)",
            zIndex: 400,
          }}
        >
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, marginLeft: "20px", color: "white" }}
          >
            {board.title}
          </Typography>
          <div style={{ display: "flex", marginRight: "20px" }}>
            <AvatarGroup max={4}>
              {viewers.map((viewer, index) => (
                <Tooltip
                  key={viewer?.email || index}
                  title={viewer?.name || viewer?.email}
                >
                  <Avatar
                    src={viewer?.image}
                    alt={viewer?.name || viewer?.email}
                    sx={{ width: 40, height: 40, marginLeft: "10px" }}
                  >
                    {viewer?.name?.substring(0, 1) ||
                      viewer?.email?.substring(0, 1)}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          </div>
          {isSmallScreen ? (
            <>
              <IconButton onClick={handleMenuClick}>
                <MoreVertIcon
                  sx={{
                    color: themeDetails[settings.cardColor]?.font || "white",
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleClick}>
                  <TeldioBadge
                    badgeContent={
                      filteredLabels?.length + filteredMembers?.length
                    }
                  >
                    <FilterListIcon /> Filters
                  </TeldioBadge>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handleOpenNotificationDrawer();
                  }}
                >
                  <Badge
                    badgeContent={
                      activity.filter(
                        (incident) => incident.acknowledged === false
                      ).length
                    }
                    color="error"
                  >
                    <CelebrationIcon /> Notifications
                  </Badge>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    setSettingsOpen(true);
                  }}
                >
                  <SettingsIcon /> Settings
                </MenuItem>
                {boardOwner === "own" && (
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setInviteOpen(true);
                    }}
                  >
                    <GroupIcon /> Invite
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    setLabelsOpen(true);
                  }}
                >
                  <LabelIcon /> Labels
                </MenuItem>
                {/* <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    setChatOpen(true);
                  }}
                >
                  <ChatBubbleIcon /> Chat
                </MenuItem> */}
              </Menu>
            </>
          ) : (
            <>
              <Button
                startIcon={
                  <TeldioBadge
                    badgeContent={
                      filteredLabels?.length + filteredMembers?.length
                    }
                  >
                    <FilterListIcon />{" "}
                  </TeldioBadge>
                }
                onClick={handleClick}
                sx={{
                  color: "white",
                  fontSize: "12pt",
                  textTransform: "none",
                  marginRight: "20px",
                }}
              >
                Filters
              </Button>
              <IconButton
                onClick={handleOpenNotificationDrawer}
                sx={{ marginRight: "20px" }}
              >
                <Badge
                  badgeContent={
                    activity.filter(
                      (incident) => incident.acknowledged === false
                    ).length
                  }
                  color="error"
                >
                  <CelebrationIcon sx={{ color: "white" }} />{" "}
                </Badge>
              </IconButton>

              <IconButton
                onClick={() => setSettingsOpen(true)}
                sx={{ marginRight: "20px" }}
              >
                <SettingsIcon sx={{ color: "white" }} />
              </IconButton>
              {boardOwner === "own" && (
                <IconButton
                  onClick={() => setInviteOpen(true)}
                  sx={{ marginRight: "20px" }}
                >
                  <GroupIcon sx={{ color: "white" }} />
                </IconButton>
              )}
              <IconButton
                onClick={() => setLabelsOpen(true)}
                sx={{ marginRight: "20px" }}
              >
                <LabelIcon sx={{ color: "white" }} />
              </IconButton>
              {/* <IconButton
                onClick={() => setChatOpen(true)}
                sx={{ marginRight: "20px" }}
              >
                <ChatBubbleIcon sx={{ color: "white" }} />
              </IconButton> */}
            </>
          )}
        </Box>
        <Box
          sx={{
            width: "250px",
            backgroundColor:
              themeDetails[settings.cardColor]?.primary || "#F1F2F4",
            position: "absolute",
            top: 110,
            right: 30,
            zIndex: 300,
            padding: showSearchBar ? "5px" : "0px",
            borderRadius: "5px 5px",
            height: showSearchBar ? "auto" : "0px",
            overflow: "hidden",
            transition: "height 0.1s ease-in-out, padding 0.1s ease-in-out",
            display: "flex",
            alignItems: "center",
            boxShadow: "5px 15px 20px rgba(0, 0, 0, 0.4)",
          }}
        >
          {showSearchBar && (
            <>
              <TextField
                autoFocus
                fullWidth
                variant="standard" 
                size="small"
                onChange={(e) => setSearchBarText(e.target.value.toString())}
                InputProps={{
                  startAdornment: <SearchIcon />, 
                  disableUnderline: true, 
                  "data-lpignore": true,
                }}
              />

              <Divider orientation="vertical" flexItem />
              <IconButton
                size="small"
                onClick={() => {
                  setSearchBarText("");
                  setShowSearchBar(false);
                }}
                sx={{ marginLeft: "5px" }}
              >
                <CloseIcon sx={{ fontSize: "20px" }} />
              </IconButton>
            </>
          )}
        </Box>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="board" direction="horizontal" type="COLUMN">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  overflowX: "auto",
                  marginTop: "10px",
                  height: "calc(100dvh - 134px)",
                  outline: "none",
                }}
              >
                {board.columnsOrder.map((columnId, index) => {
                  const column = board.columns[columnId];
                  if (!column) return null;
                  return (
                    <Draggable
                      key={columnId}
                      draggableId={columnId}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Column
                            key={columnId}
                            boardId={boardId}
                            columnId={columnId}
                            labels={labels}
                            collaborators={collaborators}
                            boardOwner={boardOwner}
                            openTask={openTask}
                            setOpenTask={setOpenTask}
                            showingArchived={showingArchived}
                            boardTitle={board.title}
                            filteredMembers={filteredMembers}
                            filteredLabels={filteredLabels}
                            logAuditForWatchers={logAuditForWatchers}
                            showingCardCount={showingCardCount}
                            reverseComments={reverseComments}
                            setReverseComments={setReverseComments}
                            logToColumn={logToColumn}
                            ownBoards={ownBoards}
                            collaboratedBoards={collaboratedBoards}
                            searchBarText={searchBarText}
                            activity={activity}
                          />
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                {addingColumn ? (
                  <Paper
                    sx={{
                      margin: "10px 10px",
                      padding: "10px",
                      width: "300px",
                      flexShrink: 0,
                      borderRadius: "20px",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      backgroundColor:
                        themeDetails[settings.cardColor]?.primary || "#F1F2F4",
                    }}
                  >
                    <TeldioTextField
                      autoFocus
                      placeholderColor={
                        themeDetails[settings.cardColor]?.font || "darkgrey"
                      }
                      margin="dense"
                      placeholder="Column Title"
                      type="text"
                      variant="outlined"
                      fullWidth
                      value={newColumnTitle}
                      onChange={(e) => setNewColumnTitle(e.target.value)}
                      sx={{ marginBottom: "10px" }}
                      size="small"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <SquaredButtonGreen
                        onClick={handleAddColumn}
                        color="primary"
                        sx={{ width: "90px", marginRight: "10px" }}
                      >
                        Add
                      </SquaredButtonGreen>
                      <IconButton onClick={handleCancelAddColumn}>
                        <CloseIcon
                          sx={{
                            color:
                              themeDetails[settings.cardColor]?.font ||
                              "darkgrey",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Paper>
                ) : (
                  <Paper
                    onClick={() => setAddingColumn(true)}
                    sx={{
                      margin: "10px 10px",
                      padding: "10px",
                      width: "300px",
                      flexShrink: 0,
                      borderRadius: "20px",
                      height: "auto",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      opacity: "0.5",
                      cursor: "pointer",
                      backgroundColor:
                        themeDetails[settings.cardColor]?.primary ||
                        "lightgray",
                    }}
                  >
                    <IconButton
                      sx={{
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                      }}
                    >
                      Add another column
                    </Typography>
                  </Paper>
                )}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <Drawer
          anchor="right"
          open={labelsOpen}
          onClose={() => setLabelsOpen(false)}
        >
          <Box
            sx={{
              width: 300,
              padding: 2,
              backgroundColor:
                themeDetails[settings.cardColor]?.primary || "white",
              color: themeDetails[settings.cardColor]?.font || "black",
              height: "100%",
            }}
          >
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              width="100%"
              sx={{ marginBottom: 1 }}
            >
              <Typography
                variant="h6"
                style={{ width: "100%", textAlign: "center" }}
              >
                Labels
              </Typography>
              <IconButton
                style={{ position: "absolute", right: 0 }}
                onClick={() => setLabelsOpen(false)}
              >
                <CloseIcon
                  sx={{
                    color: themeDetails[settings.cardColor]?.font || "black",
                  }}
                />
              </IconButton>
            </Box>
            <Divider />
            <Typography sx={{ marginTop: "10px" }}>Labels</Typography>
            <List>
              {Object.keys(labels).map((labelId) => {
                const label = labels[labelId];
                const textColor = getTextColor(label.color);
                return (
                  <ListItem
                    key={labelId}
                    onClick={() => handleLabelClick(labelId)}
                    sx={{ width: "100%", padding: "4px 1px 0px 1px" }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: 40,
                        backgroundColor: label.color,
                        borderRadius: "5px",
                        color: textColor,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        "&:hover": {
                          backgroundColor: lighten(label.color, 0.2),
                        },
                      }}
                    >
                      <ListItemText
                        primary={label.name}
                        sx={{ color: textColor, marginLeft: "10px" }}
                      />
                    </Box>
                  </ListItem>
                );
              })}
            </List>
            <SquaredButtonGreen
              fullWidth
              sx={{ marginTop: "20px" }}
              onClick={() =>
                setEditingLabel({ id: null, name: "", color: colors[0] })
              }
            >
              Add Label
            </SquaredButtonGreen>
          </Box>
        </Drawer>

        <Drawer
          anchor="right"
          open={notificationDrawerOpen}
          onClose={handleCloseNotificationDrawer}
          PaperProps={{
            sx: {
              backgroundColor:
                themeDetails[settings.cardColor]?.primary || "white",
            },
          }}
        >
          <Box
            sx={{
              width: 348,
              padding: 2,
              height: "100%",
              color: themeDetails[settings.cardColor]?.font || "black",
            }}
          >
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              width="100%"
              sx={{ marginBottom: 1 }}
            >
              <Typography
                variant="h6"
                style={{ width: "100%", textAlign: "center" }}
              >
                Notifications
              </Typography>
              <IconButton
                style={{ position: "absolute", right: 0 }}
                onClick={handleCloseNotificationDrawer}
              >
                <CloseIcon
                  sx={{
                    color: themeDetails[settings.cardColor]?.font || "black",
                  }}
                />
              </IconButton>
            </Box>
            <Divider />
            <Box sx={{ marginTop: "10px" }}>
              {activity &&
                activity.length > 0 &&
                activity.map((incident, index) => {
                  let column = findColumnByTaskId(incident.taskId);
                  return (
                    <div key={incident.taskId + index}>
                      {(incident?.type === "comment" ||
                        incident?.type === "assign" ||
                        incident?.type === "reply") && (
                        <div
                          onClick={() => {
                            setOpenTask({
                              columnId: column,
                              taskId: incident.taskId,
                            });
                            handleClearNotification(incident.uid);
                          }}
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            display: "flex",
                            padding: "3px",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              minHeight: "100px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {viewers.some(
                              (user) => user.uid === incident.mentionedBy
                            ) ? (
                              <ActiveBadge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                variant="dot"
                              >
                                <Avatar
                                  src={
                                    collaborators[incident.mentionedBy]
                                      ?.photoURL
                                  }
                                  alt={incident.mentionedBy}
                                />
                              </ActiveBadge>
                            ) : (
                              <Avatar
                                src={
                                  collaborators[incident.mentionedBy]?.photoURL
                                }
                                alt={incident.mentionedBy}
                              />
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginLeft: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography>
                                  <strong>
                                    {getDisplayName(incident?.mentionedBy)}
                                  </strong>
                                  <span
                                    style={{
                                      opacity: "0.6",
                                      margin: "0 5px",
                                    }}
                                  >
                                    {incident?.type === "comment" &&
                                      "mentioned you in a comment on"}
                                    {incident?.type === "assign" &&
                                      "assigned you to"}
                                    {incident?.type === "reply" &&
                                      "replied to a comment you posted on"}
                                  </span>
                                  <strong>
                                    {getTaskTitle(incident?.taskId)}
                                  </strong>
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    opacity: "0.6",
                                    marginX: "5px",
                                    fontSize: "10pt",
                                  }}
                                >
                                  {formatDistanceToNow(incident.createdAt)} ago
                                </Typography>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "20px",
                              display: "flex",
                              justifyContent: "flex-end",
                              marginLeft: "20px",
                            }}
                          >
                            {!incident.acknowledged ? (
                              <CampaignIcon color="error" />
                            ) : (
                              <IconButton
                                onClick={(e) => {
                                  handleDeleteNotification(e, incident);
                                }}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      )}
                      {index < activity.length - 1 && (
                        <Divider sx={{ marginY: 1 }} />
                      )}
                    </div>
                  );
                })}
            </Box>
          </Box>
        </Drawer>

        <SettingsSidebar
          open={settingsOpen}
          onClose={() => setSettingsOpen(false)}
          boardId={boardId}
          currentUser={currentUser}
          boardOwner={boardOwner}
          showingArchived={showingArchived}
          setShowingArchived={setShowingArchived}
          showingCardCount={showingCardCount}
          setShowingCardCount={setShowingCardCount}
          reverseComments={reverseComments}
          setReverseComments={setReverseComments}
          collaborators={collaborators}
        />
        <ChatSidebar
          open={chatOpen}
          onClose={() => setChatOpen(false)}
          boardId={boardId}
          currentUser={currentUser}
          boardOwner={boardOwner}
          showingArchived={showingArchived}
          setShowingArchived={setShowingArchived}
          showingCardCount={showingCardCount}
          setShowingCardCount={setShowingCardCount}
          reverseComments={reverseComments}
          setReverseComments={setReverseComments}
          collaborators={collaborators}
        />
        <InviteSidebar
          open={inviteOpen}
          onClose={() => setInviteOpen(false)}
          boardId={boardId}
          board={board}
          currentUser={currentUser}
          boardOwner={boardOwner}
        />

        <Modal
          open={Boolean(editingLabel)}
          onClose={handleCloseLabelModal}
          aria-labelledby="edit-label-modal"
          aria-describedby="edit-label-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "5px",
              p: 4,
            }}
          >
            <Typography id="edit-label-modal" variant="h6" component="h2">
              {editingLabel?.id ? "Edit Label" : "Add Label"}
            </Typography>
            <TeldioTextField
              autoFocus
              margin="dense"
              placeholder="Label Name"
              type="text"
              fullWidth
              variant="outlined"
              value={editingLabel?.id ? editingLabel?.name : newLabelName}
              onChange={(e) =>
                editingLabel?.id
                  ? setEditingLabel((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  : setNewLabelName(e.target.value)
              }
              sx={{ marginBottom: "10px" }}
            />
            <Typography variant="subtitle1">Select a color</Typography>
            <Grid container spacing={1} sx={{ marginTop: "8px" }}>
              {colors.map((color) => (
                <Grid item xs={3} key={color}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "30px",
                      backgroundColor: color,
                      cursor: "pointer",
                      border:
                        (editingLabel?.id && editingLabel.color === color) ||
                        (!editingLabel?.id && newLabelColor === color)
                          ? "2px solid #000"
                          : "2px solid transparent",
                      borderRadius: "4px",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "scale(1.1)",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                    onClick={() =>
                      editingLabel?.id
                        ? setEditingLabel((prev) => ({ ...prev, color }))
                        : setNewLabelColor(color)
                    }
                  />
                </Grid>
              ))}
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "16px",
              }}
            >
              {editingLabel?.id && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteLabel}
                >
                  Delete
                </Button>
              )}
              <SquaredButtonGreen
                variant="contained"
                color="primary"
                onClick={editingLabel?.id ? handleUpdateLabel : handleAddLabel}
              >
                {editingLabel?.id ? "Update" : "Create"}
              </SquaredButtonGreen>
            </div>
          </Box>
        </Modal>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <FilterPanel
            handleClose={handleClose}
            collaborators={convertToArray(collaborators)}
            boardPath={boardPath}
            boardOwner={boardOwner}
            labels={convertToArray(labels)}
            setFilteredMembers={setFilteredMembers}
            filteredMembers={filteredMembers}
            setFilteredLabels={setFilteredLabels}
            filteredLabels={filteredLabels}
          />
        </Popover>
      </div>
    </Box>
  );
};

export default Board;
