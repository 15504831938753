import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  IconButton,
  Stack,
  Avatar,
  Tooltip,
  Popover,
  Button,
  colors,
} from "@mui/material";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import { getDB } from "../../../firebase/firebase";
import { get, ref, update } from "firebase/database";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { format } from "date-fns";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  agnosticCommandEnter,
  convertToArray,
  sendEmail,
  themeDetails,
} from "../../../Helper/utils";
import { MentionsInput, Mention } from "react-mentions";
import "./Comments.css";
import TextRenderer from "./TextRenderer";
import CloseIcon from "@mui/icons-material/Close";
import EmojiPicker from "emoji-picker-react";
import {
  ReactionTooltip,
  SquaredButtonGreen,
  addCommentTextField,
  editCommentTextField,
  getAddCommentTextField,
  getEditCommentTextField,
  getReplyCommentTextField,
  replyCommentTextField,
} from "../../../MUI_Components/Components";
import ReactionTooltipComponent from "./ReactionTooltipComponent";
import { useSettings } from "../../../Contexts/SettingsContext";
import zIndex from "@mui/material/styles/zIndex";

const Comments = ({
  taskTitle,
  collaborators,
  comments,
  setComments,
  boardId,
  columnId,
  taskId,
  boardOwner,
  logAudit,
  taskName,
  boardTitle,
  taskPath,
}) => {
  const [comment, setComment] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editComment, setEditComment] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [reply, setReply] = useState("");
  const [replyIndex, setReplyIndex] = useState(null);
  const [react, setReact] = useState({ index: null, replyIndex: null });
  const [showReplies, setShowReplies] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser } = useAuth();
  const db = getDB();
  const inputRef = useRef(null);
  const [replyingTo, setReplyingTo] = useState("");
  const { settings } = useSettings();
  const renderPortal = useRef(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMM d 'at' h:mm a");
  };

  function handleReplyComment(index) {
    setReply("");
    handleReply(index);
  }

  function handleReplyReply(index, replyUser) {
    setReply("");
    let replyAuthor = convertToArray(collaborators).find(
      (user) => user.email === replyUser
    );
    const replyWithMention = `@[${
      replyAuthor.displayName || replyAuthor.email
    }](${replyAuthor.email}) `;
    if (reply.length === 0) {
      setReply(replyWithMention);
    }
    handleReply(index);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          replyWithMention.length,
          replyWithMention.length
        );
      }
    }, 0);
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [reply]);

  function parseMentions(input) {
    const mentionPattern = /@\[(.*?)\]\((.*?)\)/g;
    let result = "";
    let lastIndex = 0;
    let match;

    while ((match = mentionPattern.exec(input)) !== null) {
      result += input.slice(lastIndex, match.index);
      result += `@${match[1]}`;
      lastIndex = match.index + match[0].length;
    }
    result += input.slice(lastIndex);

    return result;
  }

  const handleAddComment = async () => {
    if (comment.length === 0) {
      return;
    }
    const newComment = {
      text: comment,
      author: currentUser.email,
      createdAt: new Date().toISOString(),
      reactions: [],
      replies: [],
    };
    const updatedComments = [newComment, ...comments];
    setComments(updatedComments);
    setComment("");

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });

    handleMentions(comment, updatedComments);
    logAudit(
      "Comment added",
      `${
        currentUser.displayName || currentUser.email
      } commented ${parseMentions(newComment.text)} on card ${taskName}`
    );
  };

  const handleAddReact = async (emoji, commentIndex, replyIndex = null) => {
    setReact({ index: null, replyIndex: null });
    setAnchorEl(null);

    const newReaction = {
      emoji: emoji,
      author: currentUser.email,
      createdAt: new Date().toISOString(),
    };

    const taskRef = ref(db, `${taskPath}`);
    const snapshot = await get(taskRef);

    let updatedComments = [...comments];
    let reactions;

    if (replyIndex !== null) {
      reactions =
        updatedComments[commentIndex].replies[replyIndex].reactions || [];
    } else {
      reactions = updatedComments[commentIndex].reactions || [];
    }

    const reactionExists = reactions.some(
      (reaction) =>
        reaction.emoji === emoji && reaction.author === currentUser.email
    );

    if (!reactionExists) {
      if (replyIndex !== null) {
        updatedComments[commentIndex].replies[replyIndex].reactions = [
          newReaction,
          ...reactions,
        ];
      } else {
        updatedComments[commentIndex].reactions = [newReaction, ...reactions];
      }

      setComments(updatedComments);
      await update(taskRef, { comments: updatedComments });

      logAudit(
        "Reaction added",
        `${currentUser.displayName || currentUser.email} reacted ${emoji} to ${
          updatedComments[commentIndex].text
        } on card ${taskName}`
      );
    }
  };

  const handleDeleteReact = async (commentIndex, emoji, replyIndex = null) => {
    const updatedComments = comments.map((comment, i) => {
      if (i === commentIndex) {
        if (replyIndex !== null) {
          const updatedReactions = comment.replies[replyIndex].reactions.filter(
            (reaction) =>
              !(
                reaction.author === currentUser.email &&
                reaction.emoji === emoji
              )
          );
          return {
            ...comment,
            replies: comment.replies.map((reply, j) =>
              j === replyIndex
                ? { ...reply, reactions: updatedReactions }
                : reply
            ),
          };
        } else {
          const updatedReactions = comment.reactions.filter(
            (reaction) =>
              !(
                reaction.author === currentUser.email &&
                reaction.emoji === emoji
              )
          );
          return { ...comment, reactions: updatedReactions };
        }
      }
      return comment;
    });

    setComments(updatedComments);

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });
  };

  const handleAddReply = async (commentIndex) => {
    const functions = getFunctions();

    const addActivity = httpsCallable(functions, "addActivity");

    if (reply.length === 0) {
      return;
    }
    setReplyIndex(null);

    const newReply = {
      text: reply,
      author: currentUser.email,
      createdAt: new Date().toISOString(),
      reactions: [],
    };

    const taskRef = ref(db, `${taskPath}`);
    const snapshot = await get(taskRef);

    let updatedComments = [...comments];
    updatedComments[commentIndex].replies = [
      newReply,
      ...(updatedComments[commentIndex].replies || []),
    ];

    setComments(updatedComments);
    await update(taskRef, { comments: updatedComments });

    setReply("");
    handleMentions(reply, updatedComments);

    console.log("replyer", replyingTo);
    if (replyingTo !== currentUser.email) {
      addActivity({
        boardOwner: boardOwner === "own" ? currentUser.uid : boardOwner,
        boardId,
        mentionedUser: replyingTo,
        displayName: replyingTo,
        columnId,
        type: "reply",
        taskId: taskId,
        mentionedBy: currentUser.uid,
      });
      logAudit(
        "Reply added",
        `${currentUser.displayName || currentUser.email} replied ${reply} to ${
          updatedComments[commentIndex].text
        } on card ${taskName}`
      );
    }

    setReplyingTo("");
  };

  const handleMentions = async (text, updatedComments) => {
    const mentionPattern = /@\[(.+?)\]\((.+?)\)/g;
    let match;
    const functions = getFunctions();
    const addActivity = httpsCallable(functions, "addActivity");

    while ((match = mentionPattern.exec(text)) !== null) {
      const name = match[1];
      const email = match[2];
      const mentionedUser = convertToArray(collaborators).find(
        (user) => user.email === email
      );
      if (mentionedUser) {
        await addActivity({
          boardOwner: boardOwner === "own" ? currentUser.uid : boardOwner,
          boardId,
          mentionedUser: mentionedUser.email,
          displayName: name,
          taskId,
          columnId,
          type: "comment",
          mentionedBy: currentUser.uid,
        });
        sendEmail(mentionedUser.email, "mentioned in comment", {
          boardName: boardTitle,
          fromEmail: currentUser.email,
          cardName: taskName,
          boardID: boardId,
          cardID: taskId,
          comment: parseMentions(text),
          taskTitle: taskTitle,
        });
      }
    }
  };

  const handleSaveComment = async (index) => {
    const updatedComments = comments.map((comment, i) =>
      i === index ? { ...comment, text: editComment } : comment
    );
    setComments(updatedComments);
    setEditIndex(null);
    setEditComment("");
    setIsEditing(false);

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });

    handleMentions(editComment, updatedComments);
  };

  const handleEditComment = (index) => {
    setEditIndex(index);
    setEditComment(comments[index].text);
    setIsEditing(true);
  };

  const handleReply = (index) => {
    setReplyIndex(index);
  };

  const handleSetReplyingTo = (person) => {
    setReplyingTo(person);
  };

  const handleDeleteComment = async (index) => {
    const updatedComments = comments.filter((_, i) => i !== index);
    setComments(updatedComments);

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });
  };

  const handleDeleteReply = async (commentIndex, replyIndex) => {
    const updatedComments = comments.map((comment, i) => {
      if (i === commentIndex) {
        const updatedReplies = comment.replies.filter(
          (_, j) => j !== replyIndex
        );
        return { ...comment, replies: updatedReplies };
      }
      return comment;
    });
    setComments(updatedComments);

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });
  };

  const getUserInfoByEmail = (email, users) => {
    const userEntry = Object.entries(users).find(
      ([uid, userInfo]) => userInfo.email === email
    );
    if (userEntry) {
      const [uid, userInfo] = userEntry;
      return { uid, ...userInfo };
    }
    return null;
  };

  const collaboratorsData = convertToArray(collaborators).map(
    (collaborator) => ({
      id: collaborator.email,
      display: collaborator.displayName || collaborator.email.split("@")[0],
      photoURL: collaborator.photoURL,
    })
  );

  const renderSuggestion = (
    suggestion,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return (
      <div
        key={suggestion.id}
        style={{
          display: "flex",
          color: themeDetails[settings.cardColor]?.font || "black",
          alignItems: "center",
          backgroundColor: !focused
            ? themeDetails[settings.cardColor]?.primary || "lightgray"
            : themeDetails[settings.cardColor]?.secondary || "white",
          padding: "5px",
          borderRadius: "5px",
          margin: "2px 0",
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          zIndex: 100, // Ensure it is above the background
        }}
      >
        <Avatar
          src={suggestion.photoURL}
          alt={suggestion.display}
          style={{ marginRight: "10px" }}
        />
        <span>{suggestion.display}</span>
      </div>
    );
  };

  // const renderPortal = (
  //   suggestion,
  //   search,
  //   highlightedDisplay,
  //   index,
  //   focused
  // ) => {
  //   return (
  //     <div
  //       key={suggestion.id + 100}
  //       style={{
  //         color: themeDetails[settings.cardColor]?.font || "red",
  //         backgroundColor: focused
  //           ? themeDetails[settings.cardColor]?.primary || "red"
  //           : themeDetails[settings.cardColor]?.secondary || "red",
  //       }}
  //     >
  //       {highlightedDisplay}
  //     </div>
  //   );
  // };

  const handleOpenPopover = (event, index, replyIndex = null) => {
    setAnchorEl(event.currentTarget);
    setReact({ index, replyIndex });
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setReact({ index: null, replyIndex: null });
  };

  const shouldShowAvatar = (current, previous) => {
    if (!previous) return true;

    if (current?.replies?.length > 0 || previous?.replies?.length > 0) {
      return true;
    }

    const currentTime = new Date(current.createdAt);
    const previousTime = new Date(previous.createdAt);
    const timeDifference = (previousTime - currentTime) / (1000 * 60); // Difference in minutes

    let shouldShow = timeDifference > 5 || current.author !== previous.author;

    return shouldShow;
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ChatBubbleOutlineIcon />
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Comments
        </Typography>
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          marginTop: "20px",
        }}
      >
        <Avatar src={currentUser.photoURL} sx={{ marginRight: "10px" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            width: "85%",
          }}
        >
          <div ref={renderPortal}></div>

          <MentionsInput
            value={comment}
            onChange={(e, newValue) => setComment(newValue)}
            style={{
              ...getAddCommentTextField(
                themeDetails[settings.cardColor]?.font,
                themeDetails[settings.cardColor]?.primary
              ),
              position: "relative",
              zIndex: 0,
              outline: "none",
              boxShadow: "none",
              lineHeight: "1",
              whiteSpace: "pre-wrap", // Ensure long words break properly
              wordWrap: "break-word", // Break long words
              overflowWrap: "break-word", // Break on overflow
            }}
            suggestionsPortalHost={renderPortal.current}
            placeholder="Add a comment"
            forceSuggestionsAboveCursor={true}
            onKeyDown={(e) => {
              if (agnosticCommandEnter(e)) {
                handleAddComment();
              }
            }}
          >
            <Mention
              trigger="@"
              data={collaboratorsData}
              renderSuggestion={renderSuggestion}
              style={{
                backgroundColor: "#cad957",
                color: "black",
                zIndex: 10,
                position: "relative",
                padding: "4px 0.5px",
                borderRadius: "4px",
                lineHeight: "1.5",
              }}
              displayTransform={(id, display) => `@${display}`}
            />
          </MentionsInput>

          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "3px",
            }}
          >
            <SquaredButtonGreen
              onClick={handleAddComment}
              sx={{
                height: "30px",
                textTransform: "none",
                marginRight: "10px",
                marginTop: 1,
              }}
            >
              Save
            </SquaredButtonGreen>
          </div>
        </div>
      </div>

      <List>
        {comments.map((comment, index) => {
          let user = getUserInfoByEmail(comment.author, collaborators);
          let showAvatar = shouldShowAvatar(
            comment,
            comments[index - 1] || null
          );

          const reactionCounts = comment?.reactions?.reduce((acc, reaction) => {
            if (!reaction || !reaction.emoji || !reaction.author) return acc;

            if (!acc[reaction.emoji]) {
              acc[reaction.emoji] = {
                count: 0,
                indices: [],
                isAuthor: false,
                authors: [],
              };
            }
            acc[reaction.emoji].count += 1;
            acc[reaction.emoji].indices.push(index);
            acc[reaction.emoji].authors.push(reaction.author);
            if (reaction.author === currentUser.email) {
              acc[reaction.emoji].isAuthor = true;
            }
            return acc;
          }, {});

          return (
            <ListItem key={index} sx={{ padding: 0, marginBottom: "5px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                {showAvatar ? (
                  <Tooltip
                    title={user?.displayName || user?.email.split("@")[0]}
                  >
                    <Avatar
                      src={user?.photoURL}
                      alt={user?.displayName || user?.email.split("@")[0]}
                      sx={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <div style={{ width: "55px" }} />
                )}
                <div style={{ width: "100%" }}>
                  {showAvatar && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-start",
                        marginBottom: "3px",
                      }}
                    >
                      <Typography
                        sx={{
                          mr: "10px",
                          fontSize: "11pt",
                          fontWeight: "bold",
                        }}
                      >
                        {user?.displayName || user?.email.split("@")[0]}
                      </Typography>
                      <Typography sx={{ fontSize: "8pt" }}>
                        {formatDate(comment.createdAt)}
                      </Typography>
                    </div>
                  )}
                  {editIndex === index ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "right",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            themeDetails[settings.cardColor]?.primary,
                          width: "95%",
                          height: "auto",
                          minHeight: "40px",
                          borderRadius: "9px",
                          alignItems: "center",
                          padding: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <MentionsInput
                          value={editComment}
                          onChange={(e, newValue) => setEditComment(newValue)}
                          style={{
                            ...getAddCommentTextField(
                              themeDetails[settings.cardColor]?.font,
                              themeDetails[settings.cardColor]?.primary
                            ),
                            width: "100%",
                            position: "relative", // Ensure positioning context for the zIndex
                            zIndex: 0, // Ensure it is above the background
                          }}
                          forceSuggestionsAboveCursor={true}
                          suggestionsPortalHost={renderPortal.current}
                          placeholder="Edit comment"
                          onKeyDown={(e) => {
                            if (agnosticCommandEnter(e)) {
                              handleSaveComment(index);
                            }
                          }}
                        >
                          <Mention
                            trigger="@"
                            data={collaboratorsData}
                            renderSuggestion={renderSuggestion}
                            style={{
                              backgroundColor: "#cad957",
                              color: "black",
                              zIndex: 10, // Ensure it is above other elements
                              position: "relative", // Ensure positioning context for the zIndex
                              padding: "2px 0.5px", // Padding for better appearance
                              borderRadius: "4px", // Rounded corners for better appearance
                            }}
                            displayTransform={(id, display) => ` @${display} `}
                          />
                        </MentionsInput>
                        <IconButton
                          onClick={() => handleSaveComment(index)}
                          sx={{ color: themeDetails[settings.cardColor]?.font }}
                        >
                          <ArrowCircleUpIcon />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div
                        style={{
                          backgroundColor:
                            themeDetails[settings.cardColor]?.primary ||
                            "white",
                          color:
                            themeDetails[settings.cardColor]?.font || "black",
                          width: "calc(100% - 20px)",
                          height: "auto",
                          borderRadius: "25px",
                          alignItems: "center",
                          padding: "10px",
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        }}
                      >
                        <Popover
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={handleClosePopover}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <EmojiPicker
                            onEmojiClick={(e) =>
                              handleAddReact(
                                e.emoji,
                                react.index,
                                react.replyIndex
                              )
                            }
                          />
                        </Popover>

                        <ReactionTooltip
                          placement="top-end"
                          open={tooltipOpen === index}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor:
                                  themeDetails[settings.cardColor]?.primary ||
                                  "white",
                                borderRadius: "20px",
                                color: "white",
                                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                                opacity: 1,
                              },
                            },
                          }}
                          onOpen={() => setTooltipOpen(index)}
                          onClose={() => setTooltipOpen(false)}
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [100, -14],
                                  },
                                },
                              ],
                            },
                          }}
                          title={
                            <ReactionTooltipComponent
                              setTooltipOpen={setTooltipOpen}
                              handleAddReact={handleAddReact}
                              handleOpenPopover={handleOpenPopover}
                              index={index}
                              handleReply={handleReplyComment}
                              handleEditComment={handleEditComment}
                              commentAuthor={comment.author}
                              handleDeleteComment={handleDeleteComment}
                              boardOwner={boardOwner}
                              boardId={boardId}
                              columnId={columnId}
                              setReplyingTo={handleSetReplyingTo}
                            />
                          }
                        >
                          <Typography
                            sx={{
                              fontSize: "12pt",
                              width: "100%",
                              overflowWrap: "break-word",
                              marginLeft: 0.2,
                            }}
                          >
                            <TextRenderer
                              style={{ display: "flex", alignItems: "center" }}
                              text={comment.text}
                            />
                          </Typography>
                        </ReactionTooltip>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {comment?.reactions?.length > 0 && (
                          <div style={{ marginTop: "2px" }}>
                            {Object.entries(reactionCounts).map(
                              ([emoji, data]) => (
                                <Tooltip
                                  key={emoji}
                                  disableInteractive
                                  title={
                                    <>
                                      {data?.authors?.map((author) => {
                                        const replyUser = getUserInfoByEmail(
                                          author,
                                          collaborators
                                        );
                                        return (
                                          <Typography
                                            sx={{ textAlign: "center" }}
                                          >
                                            {replyUser?.displayName || author}
                                          </Typography>
                                        );
                                      })}
                                    </>
                                  }
                                  arrow
                                >
                                  <span
                                    onClick={() => {
                                      if (data.isAuthor) {
                                        handleDeleteReact(index, emoji);
                                      } else {
                                        handleAddReact(emoji, index);
                                      }
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      border: data.isAuthor
                                        ? "2px solid #A0AD39"
                                        : "2px solid lightgrey",
                                      borderRadius: "5px",
                                      padding: "2px 4px",
                                      margin: "2px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                      backgroundColor: data.isAuthor
                                        ? "#E3E7BE"
                                        : "transparent",
                                    }}
                                  >
                                    {emoji}
                                    <span style={{ marginLeft: "6px" }}>
                                      {data.count}
                                    </span>
                                  </span>
                                </Tooltip>
                              )
                            )}
                          </div>
                        )}
                      </div>
                      <>
                        {replyIndex === index && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Avatar
                              src={currentUser.photoURL}
                              sx={{ marginRight: "10px" }}
                            />

                            <MentionsInput
                              value={reply}
                              autoFocus
                              inputRef={inputRef}
                              onChange={(e, newValue) => setReply(newValue)}
                              style={{
                                ...getAddCommentTextField(
                                  themeDetails[settings.cardColor]?.font,
                                  themeDetails[settings.cardColor]?.primary
                                ),
                                width: "100%",
                                position: "relative", // Ensure positioning context for the zIndex
                                zIndex: 0, // Ensure it is above the background
                              }}
                              placeholder="Add a reply"
                              forceSuggestionsAboveCursor={true}
                              suggestionsPortalHost={renderPortal.current}
                              onKeyDown={(e) => {
                                if (agnosticCommandEnter(e)) {
                                  handleAddReply(index);
                                }
                              }}
                            >
                              <Mention
                                trigger="@"
                                data={collaboratorsData}
                                renderSuggestion={renderSuggestion}
                                style={{
                                  backgroundColor: "#cad957",
                                  color: "black",
                                  zIndex: 10, // Ensure it is above other elements
                                  position: "relative", // Ensure positioning context for the zIndex
                                  padding: "2px 0.5px", // Padding for better appearance
                                  borderRadius: "4px", // Rounded corners for better appearance
                                }}
                                displayTransform={(id, display) =>
                                  ` @${display} `
                                }
                              />
                            </MentionsInput>
                            <IconButton
                              onClick={() => handleAddReply(index)}
                              sx={{
                                color: themeDetails[settings.cardColor]?.font,
                              }}
                            >
                              <ArrowCircleUpIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => setReplyIndex(null)}
                              sx={{
                                color: themeDetails[settings.cardColor]?.font,
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        )}
                      </>
                      {comment?.replies
                        ?.slice(0, showReplies ? comment.replies.length : 2)
                        .map((reply, replyIndex) => {
                          const replyUser = getUserInfoByEmail(
                            reply.author,
                            collaborators
                          );
                          let showAvatar = shouldShowAvatar(
                            reply,
                            comment?.replies?.slice(
                              0,
                              showReplies ? comment.replies.length : 2
                            )[replyIndex - 1] || null
                          );

                          const replyReactionCounts = reply?.reactions?.reduce(
                            (acc, reaction) => {
                              if (
                                !reaction ||
                                !reaction.emoji ||
                                !reaction.author
                              )
                                return acc;

                              if (!acc[reaction.emoji]) {
                                acc[reaction.emoji] = {
                                  count: 0,
                                  indices: [],
                                  isAuthor: false,
                                  authors: [],
                                };
                              }
                              acc[reaction.emoji].count += 1;
                              acc[reaction.emoji].indices.push(replyIndex);
                              acc[reaction.emoji].authors.push(reaction.author);
                              if (reaction.author === currentUser.email) {
                                acc[reaction.emoji].isAuthor = true;
                              }
                              return acc;
                            },
                            {}
                          );

                          return (
                            <div
                              key={replyIndex}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginTop: "10px",
                              }}
                            >
                              {showAvatar ? (
                                <Tooltip
                                  title={
                                    replyUser?.displayName ||
                                    replyUser?.email.split("@")[0]
                                  }
                                >
                                  <Avatar
                                    src={replyUser?.photoURL}
                                    alt={
                                      replyUser?.displayName ||
                                      replyUser?.email.split("@")[0]
                                    }
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                <div style={{ width: "55px" }}></div>
                              )}
                              <div style={{ width: "100%" }}>
                                {showAvatar && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                      justifyContent: "flex-start",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        mr: "10px",
                                        fontSize: "11pt",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {replyUser?.displayName ||
                                        replyUser?.email.split("@")[0]}
                                    </Typography>
                                    <Typography sx={{ fontSize: "8pt" }}>
                                      {formatDate(reply.createdAt)}
                                    </Typography>
                                  </div>
                                )}
                                <div
                                  style={{
                                    backgroundColor:
                                      themeDetails[settings.cardColor]
                                        ?.primary || "white",
                                    color:
                                      themeDetails[settings.cardColor]?.font ||
                                      "black",
                                    width: "calc(100% - 20px)",
                                    height: "auto",
                                    borderRadius: "25px",
                                    alignItems: "center",
                                    padding: "10px",
                                    marginLeft: "00px",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                                  }}
                                >
                                  <ReactionTooltip
                                    placement="top-end"
                                    open={
                                      tooltipOpen?.reply === true &&
                                      tooltipOpen?.index === index &&
                                      tooltipOpen?.replyIndex === replyIndex
                                    }
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor:
                                            themeDetails[settings.cardColor]
                                              ?.primary || "white",
                                          borderRadius: "30px",
                                          color:
                                            themeDetails[settings.cardColor]
                                              ?.font || "black",
                                          boxShadow:
                                            "0px 10px 15px -3px rgba(0,0,0,0.1)",
                                          opacity: 1,
                                        },
                                      },
                                    }}
                                    onOpen={() =>
                                      setTooltipOpen({
                                        reply: true,
                                        index: index,
                                        replyIndex: replyIndex,
                                      })
                                    }
                                    onClose={() => setTooltipOpen(false)}
                                    slotProps={{
                                      popper: {
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [100, -14],
                                            },
                                          },
                                        ],
                                      },
                                    }}
                                    title={
                                      <ReactionTooltipComponent
                                        setTooltipOpen={setTooltipOpen}
                                        handleAddReact={handleAddReact}
                                        handleOpenPopover={handleOpenPopover}
                                        index={index}
                                        replyIndex={replyIndex}
                                        handleReply={handleReply}
                                        author={reply.author}
                                        handleReplyReply={handleReplyReply}
                                        handleDeleteReply={handleDeleteReply}
                                        type="reply"
                                        replyAuthor={reply.author}
                                        setReplyingTo={handleSetReplyingTo}
                                      />
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "12pt",
                                        width: "100%",
                                        overflowWrap: "break-word",
                                        marginLeft: 0.2,
                                      }}
                                    >
                                      <TextRenderer
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        text={reply.text}
                                      />
                                    </Typography>
                                  </ReactionTooltip>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {reply?.reactions?.length > 0 && (
                                    <div style={{ marginTop: "2px" }}>
                                      {Object.entries(replyReactionCounts).map(
                                        ([emoji, data]) => (
                                          <Tooltip
                                            key={emoji}
                                            disableInteractive
                                            title={
                                              <>
                                                {data?.authors?.map(
                                                  (author) => {
                                                    const replyUser =
                                                      getUserInfoByEmail(
                                                        author,
                                                        collaborators
                                                      );
                                                    return (
                                                      <Typography
                                                        sx={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {replyUser?.displayName ||
                                                          author}
                                                      </Typography>
                                                    );
                                                  }
                                                )}
                                              </>
                                            }
                                            arrow
                                          >
                                            <span
                                              onClick={() => {
                                                if (data.isAuthor) {
                                                  handleDeleteReact(
                                                    index,
                                                    emoji,
                                                    replyIndex
                                                  );
                                                } else {
                                                  handleAddReact(
                                                    emoji,
                                                    index,
                                                    replyIndex
                                                  );
                                                }
                                              }}
                                              style={{
                                                cursor: "pointer",
                                                border: data.isAuthor
                                                  ? "2px solid #A0AD39"
                                                  : "2px solid lightgrey",
                                                borderRadius: "5px",
                                                padding: "2px 4px",
                                                margin: "2px",
                                                display: "inline-flex",
                                                alignItems: "center",
                                                backgroundColor: data.isAuthor
                                                  ? "#E3E7BE"
                                                  : "transparent",
                                              }}
                                            >
                                              {emoji}
                                              <span
                                                style={{ marginLeft: "6px" }}
                                              >
                                                {data.count}
                                              </span>
                                            </span>
                                          </Tooltip>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {comment.replies?.length > 2 && (
                        <Typography
                          sx={{
                            fontSize: "9pt",
                            textDecoration: "underline",
                            cursor: "pointer",
                            marginTop: "5px",
                            textAlign: "center",
                          }}
                          onClick={() => setShowReplies((prev) => !prev)}
                        >
                          {showReplies ? "Hide replies" : "Show all replies"}
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              </div>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default Comments;
