import React, { useState, useEffect } from "react";
import { ref, get, set, push, update, remove } from "firebase/database";
import { getDB, getStorage } from "../../firebase/firebase"; // Adjust the path as necessary
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Snackbar,
  Alert,
  Box,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Chip,
  OutlinedInput,
} from "@mui/material";
import {
  Edit,
  Archive,
  Unarchive,
  Add,
  Publish,
  Delete,
} from "@mui/icons-material";
import { ColorButton, TeldioSwitch } from "../../MUI_Components/Components";
import { useDropzone } from "react-dropzone";
import { uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"; // Import storage functions directly from firebase/storage
import { useAuth } from "../../firebase/contexts/AuthContext";
import { getRoles } from "../../Helper/utils";

const WikiManager = () => {
  const [articles, setArticles] = useState([]);
  const [topics, setTopics] = useState([]);
  const [roles] = useState(getRoles());
  const [newTopic, setNewTopic] = useState("");
  const [open, setOpen] = useState(false);
  const [manageTopicsOpen, setManageTopicsOpen] = useState(false);
  const [currentArticle, setCurrentArticle] = useState({
    name: "",
    description: "",
    iframeLink: "",
    imageLink: "",
    published: false,
    archived: false,
    topic: "",
    roles: [],
  });
  const [isEdit, setIsEdit] = useState(false);
  const { currentUser } = useAuth();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [error, setError] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);

  useEffect(() => {
    fetchArticles();
    fetchTopics();
  }, [currentUser]);

  const fetchArticles = async () => {
    if (!currentUser) return;

    const db = getDB();
    const settingsRef = ref(db, `/articles`);
    try {
      const snapshot = await get(settingsRef);
      const data = snapshot.val();
      setArticles(
        data
          ? Object.entries(data).map(([id, article]) => ({
              id,
              ...article,
              roles: article.roles || [], // Ensure roles is an array
            }))
          : []
      );
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const fetchTopics = async () => {
    if (!currentUser) return;

    const db = getDB();
    const topicsRef = ref(db, `/topics`);
    try {
      const snapshot = await get(topicsRef);
      const data = snapshot.val();
      setTopics(data ? Object.values(data) : []);
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  const handleAddEditArticle = async () => {
    if (!currentUser) return;

    if (!currentArticle.topic) {
      setError("Topic must be selected");
      setOpenFail(true);
      return;
    }

    const db = getDB();
    const settingsRef = ref(db, `/articles`);
    try {
      if (isEdit) {
        await set(ref(db, `/articles/${currentArticle.id}`), currentArticle);
      } else {
        const newArticleRef = push(settingsRef);
        await set(newArticleRef, currentArticle);
      }
      fetchArticles();
      handleClose();
      setOpenSuccess(true);
    } catch (error) {
      console.error("Error adding/editing article:", error);
      setOpenFail(true);
    }
  };

  const handleArchiveToggle = async (id, archived) => {
    if (!currentUser) return;

    const db = getDB();
    const articleRef = ref(db, `/articles/${id}`);
    try {
      await update(articleRef, { archived: !archived });
      fetchArticles();
      setOpenSuccess(true);
    } catch (error) {
      console.error("Error archiving/unarchiving article:", error);
      setOpenFail(true);
    }
  };

  const handlePublishToggle = async (id, published) => {
    if (!currentUser) return;

    if (
      !currentArticle.name ||
      !currentArticle.description ||
      !currentArticle.iframeLink ||
      !currentArticle.imageLink
    ) {
      setError("All fields must be filled to publish");
      setOpenFail(true);
      return;
    }

    const db = getDB();
    const articleRef = ref(db, `/articles/${id}`);
    try {
      await update(articleRef, { published: !published });
      fetchArticles();
      setOpenSuccess(true);
    } catch (error) {
      console.error("Error publishing/unpublishing article:", error);
      setOpenFail(true);
    }
  };

  const handleOpen = (article = null) => {
    setIsEdit(!!article);
    setCurrentArticle(
      article || {
        name: "",
        description: "",
        iframeLink: "",
        imageLink: "",
        published: false,
        archived: false,
        topic: "",
        roles: [],
      }
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseFail = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFail(false);
  };

  const handleDelete = (article) => {
    setArticleToDelete(article);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (!currentUser || !articleToDelete) return;

    const db = getDB();
    const articleRef = ref(db, `/articles/${articleToDelete.id}`);
    try {
      await remove(articleRef);
      fetchArticles();
      setDeleteDialogOpen(false);
      setOpenSuccess(true);
    } catch (error) {
      console.error("Error deleting article:", error);
      setOpenFail(true);
    }
  };

  const handleAddTopic = async () => {
    if (!currentUser || !newTopic) return;

    const db = getDB();
    const topicsRef = ref(db, `/topics`);
    try {
      const newTopicRef = push(topicsRef);
      await set(newTopicRef, newTopic);
      setTopics([...topics, newTopic]);
      setNewTopic("");
    } catch (error) {
      console.error("Error adding topic:", error);
      setOpenFail(true);
    }
  };

  const handleRemoveTopic = async (topicToRemove) => {
    if (!currentUser) return;

    const db = getDB();
    const topicsRef = ref(db, `/topics`);
    const articlesRef = ref(db, `/articles`);
    try {
      const topicSnapshot = await get(topicsRef);
      const topicData = topicSnapshot.val();
      const topicKey = Object.keys(topicData).find(
        (key) => topicData[key] === topicToRemove
      );

      if (topicKey) {
        await remove(ref(db, `/topics/${topicKey}`));
        const articleSnapshot = await get(articlesRef);
        const articleData = articleSnapshot.val();

        const updates = {};
        for (const id in articleData) {
          if (articleData[id].topic === topicToRemove) {
            updates[`/articles/${id}/topic`] = "";
          }
        }
        await update(ref(db), updates);

        fetchTopics();
        fetchArticles();
        setOpenSuccess(true);
      }
    } catch (error) {
      console.error("Error removing topic:", error);
      setOpenFail(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      const file = acceptedFiles[0];
      const storage = getStorage();
      const storageRef = storage.ref(`Images/${file.name}`);

      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setCurrentArticle({ ...currentArticle, imageLink: downloadURL });
      } catch (error) {
        console.error("Error uploading image:", error);
        setOpenFail(true);
      }
    },
  });

  const handleRemoveImage = async () => {
    if (!currentUser || !currentArticle.imageLink) return;

    const storage = getStorage();
    const imageRef = storage.refFromURL(currentArticle.imageLink);

    try {
      await deleteObject(imageRef);
      setCurrentArticle({ ...currentArticle, imageLink: "" });
      setOpenSuccess(true);
    } catch (error) {
      console.error("Error removing image:", error);
      setOpenFail(true);
    }
  };

  const handleRolesChange = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentArticle({
      ...currentArticle,
      roles: typeof value === "string" ? value.split(",") : value,
    });
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Tooltip
          title="Add Article"
          sx={{ marginRight: "10px", marginTop: "10px" }}
        >
          <IconButton onClick={() => handleOpen()}>
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Manage Topics"
          sx={{ marginRight: "10px", marginTop: "10px" }}
        >
          <IconButton onClick={() => setManageTopicsOpen(true)}>
            <Edit />
          </IconButton>
        </Tooltip>
      </div>

      <Box sx={{ marginLeft: 4, marginRight: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Unpublished Articles
        </Typography>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {articles
            .filter((article) => !article.published && !article.archived)
            .map((article) => (
              <Card
                key={article.id}
                style={{
                  width: "300px",
                  backgroundColor: "palegoldenrod",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={article.imageLink}
                  alt={article.name}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {article.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {article.description}
                  </Typography>
                </CardContent>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, marginLeft: "10px" }}>
                  {article.roles &&
                    article.roles.map((role, index) => {
                      const roleInfo = roles.find((r) => r.value === role);
                      return (
                        <Chip
                          key={index}
                          label={roleInfo ? roleInfo.label : role}
                          style={{
                            backgroundColor:
                              role === "admin"
                                ? "#FD0061" // Custom hex color for admin
                                : role === "avtec"
                                ? "#A652BB" // Custom hex color for avtec
                                : role === "teldioSales"
                                ? "#A0AD39" // Custom hex color for teldioSales
                                : role === "teldioSupport"
                                ? "#0099E1" // Custom hex color for teldioSupport
                                : "#597E8D", // Custom hex color for other roles
                          }}
                        />
                      );
                    })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "flex-end",
                    padding: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: article.topic ? "black" : "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {article.topic ? (
                      <Chip label={article.topic} />
                    ) : (
                      "NO TOPIC ARTICLE HIDDEN"
                    )}
                  </Typography>

                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleOpen(article)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Publish">
                    <IconButton
                      onClick={() =>
                        handlePublishToggle(article.id, article.published)
                      }
                    >
                      <Publish />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDelete(article)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            ))}
        </div>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Published Articles
        </Typography>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {articles
            .filter((article) => article.published && !article.archived)
            .map((article) => (
              <Card
                key={article.id}
                style={{
                  width: "300px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={article.imageLink}
                  alt={article.name}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {article.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {article.description}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    marginLeft: "10px",
                  }}
                >
                  {article.roles &&
                    article.roles.map((role, index) => {
                      const roleInfo = roles.find((r) => r.value === role);
                      return (
                        <Chip
                          key={index}
                          label={roleInfo ? roleInfo.label : role}
                          style={{
                            backgroundColor:
                              role === "admin"
                                ? "#FD0061" // Custom hex color for admin
                                : role === "avtec"
                                ? "#A652BB" // Custom hex color for avtec
                                : role === "teldioSales"
                                ? "#A0AD39" // Custom hex color for teldioSales
                                : role === "teldioSupport"
                                ? "#0099E1" // Custom hex color for teldioSupport
                                : "#597E8D", // Custom hex color for other roles
                          }}
                        />
                      );
                    })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "flex-end",
                    padding: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: article.topic ? "black" : "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {article.topic ? (
                      <Chip label={article.topic} />
                    ) : (
                      "NO TOPIC ARTICLE HIDDEN"
                    )}
                  </Typography>

                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleOpen(article)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={article.archived ? "Unarchive" : "Archive"}>
                    <IconButton
                      onClick={() =>
                        handleArchiveToggle(article.id, article.archived)
                      }
                    >
                      {article.archived ? <Unarchive /> : <Archive />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            ))}
        </div>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Archived Articles
        </Typography>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {articles
            .filter((article) => article.archived)
            .map((article) => (
              <Card
                key={article.id}
                style={{
                  width: "300px",
                  backgroundColor: "lightgrey",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={article.imageLink}
                  alt={article.name}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {article.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {article.description}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "flex-end",
                    padding: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: article.topic ? "black" : "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {article.topic ? (
                      <Chip label={article.topic} />
                    ) : (
                      "NO TOPIC ARTICLE HIDDEN"
                    )}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      marginLeft: "10px",
                    }}
                  >
                    {article.roles &&
                      article.roles.map((role, index) => {
                        const roleInfo = roles.find((r) => r.value === role);
                        return (
                          <Chip
                            key={index}
                            label={roleInfo ? roleInfo.label : role}
                            style={{
                              backgroundColor:
                                role === "admin"
                                  ? "#FD0061" // Custom hex color for admin
                                  : role === "avtec"
                                  ? "#A652BB" // Custom hex color for avtec
                                  : role === "teldioSales"
                                  ? "#A0AD39" // Custom hex color for teldioSales
                                  : role === "teldioSupport"
                                  ? "#0099E1" // Custom hex color for teldioSupport
                                  : "#597E8D", // Custom hex color for other roles
                            }}
                          />
                        );
                      })}
                  </Box>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleOpen(article)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={article.archived ? "Unarchive" : "Archive"}>
                    <IconButton
                      onClick={() =>
                        handleArchiveToggle(article.id, article.archived)
                      }
                    >
                      {article.archived ? <Unarchive /> : <Archive />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDelete(article)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            ))}
        </div>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? "Edit Article" : "Add Article"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={currentArticle.name}
            onChange={(e) =>
              setCurrentArticle({ ...currentArticle, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            value={currentArticle.description}
            onChange={(e) =>
              setCurrentArticle({
                ...currentArticle,
                description: e.target.value,
              })
            }
          />
          <TextField
            margin="dense"
            label="Iframe Link"
            type="url"
            fullWidth
            value={currentArticle.iframeLink}
            onChange={(e) =>
              setCurrentArticle({
                ...currentArticle,
                iframeLink: e.target.value,
              })
            }
          />
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              border: "2px dashed #ccc",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <input {...getInputProps()} />
            {currentArticle.imageLink ? (
              <div>
                <img
                  src={currentArticle.imageLink}
                  alt="Article"
                  style={{ maxWidth: "100%" }}
                />
                <Button onClick={handleRemoveImage} color="error">
                  Remove Image
                </Button>
              </div>
            ) : (
              <p>Drag & drop an image here, or click to select one</p>
            )}
          </div>
          <FormControl fullWidth sx={{ marginTop: "20px" }}>
            <InputLabel>Topic</InputLabel>
            <Select
              value={currentArticle.topic}
              onChange={(e) =>
                setCurrentArticle({
                  ...currentArticle,
                  topic: e.target.value,
                })
              }
              label="Topic"
            >
              {topics.map((topic, index) => (
                <MenuItem key={index} value={topic}>
                  {topic}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: "20px" }}>
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={currentArticle.roles}
              onChange={handleRolesChange}
              input={<OutlinedInput label="Roles" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {roles.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <TeldioSwitch
                  checked={currentArticle.published}
                  onChange={(e) =>
                    setCurrentArticle({
                      ...currentArticle,
                      published: e.target.checked,
                    })
                  }
                  color="primary"
                  sx={{ marginRight: "10px", marginLeft: "20px" }}
                />
              }
              label="Published"
            />
            <FormControlLabel
              labelPlacement="start"
              control={
                <TeldioSwitch
                  checked={currentArticle.archived}
                  onChange={(e) =>
                    setCurrentArticle({
                      ...currentArticle,
                      archived: e.target.checked,
                    })
                  }
                  color="primary"
                  sx={{ marginRight: "10px", marginLeft: "20px" }}
                />
              }
              label="Archived"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ColorButton onClick={handleAddEditArticle} color="primary">
            {isEdit ? "Update" : "Add"}
          </ColorButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={manageTopicsOpen}
        onClose={() => setManageTopicsOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Manage Topics</DialogTitle>
        <DialogContent>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Add a New Topic
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <TextField
              label="New Topic"
              type="text"
              fullWidth
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
            />
            <IconButton color="primary" onClick={handleAddTopic}>
              <Add />
            </IconButton>
          </Box>
          <Typography variant="h6" sx={{ marginTop: 4, marginBottom: 2 }}>
            Existing Topics
          </Typography>
          <Box>
            {topics.map((topic, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 1,
                    marginTop: 1,
                  }}
                >
                  <Typography>{topic}</Typography>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveTopic(topic)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
                <Divider />
              </>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setManageTopicsOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this article?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFail}
        autoHideDuration={6000}
        onClose={handleCloseFail}
      >
        <Alert
          onClose={handleCloseFail}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error || "Error!"}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default WikiManager;
