import React, { useState, useEffect } from "react";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { ref, onValue, set } from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";

const Clipboard = () => {
  const [clipboard, setClipboard] = useState("");
  const [lastUpdated, setLastUpdated] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("loading");
  const { currentUser } = useAuth();
  const db = getDB();

  useEffect(() => {
    if (!currentUser) return;

    const clipboardRef = ref(db, `/uid/${currentUser.uid}/clipboard`);
    const unsubscribe = onValue(
      clipboardRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setClipboard(data.text);
          setLastUpdated(data.timestamp);
          setConnectionStatus("connected");
        }
      },
      (error) => {
        setConnectionStatus("error");
        console.error(error);
      }
    );

    return () => unsubscribe();
  }, [db, currentUser]);

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setClipboard(newText);
    set(ref(db, `/uid/${currentUser.uid}/clipboard`), {
      text: newText,
      timestamp: Date.now(),
    });
  };

  const getStatusIcon = () => {
    switch (connectionStatus) {
      case "connected":
        return <CheckCircleOutline style={{ color: "green", animation: "pulse 2s infinite" }} />;
      case "error":
        return <ErrorOutline style={{ color: "red" }} />;
      default:
        return <CircularProgress size={24} />;
    }
  };

  const timeSince = (timestamp) => {
    const now = Date.now();
    const seconds = Math.floor((now - timestamp) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          backgroundColor: "white",
          height: "80%",
          marginTop: "50px",
          width: "clamp(500px, 60%, 600px)",
          borderRadius: "30px",
          boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
          padding: "20px 20px 0px",
          position: "relative",
          paddingBottom: "20px",
        }}
      >
        <div style={{ position: "absolute", top: "20px", right: "20px" }}>
          {getStatusIcon()}
        </div>
        <Typography variant="h4" style={{ marginTop: "10px", textAlign: "center" }}>
          Personal Universal Clipboard
        </Typography>
        <TextField
          fullWidth
          sx={{ marginTop: "20px", height: "80%" }}
          multiline
          minRows={8}
          maxRows={20}
          value={clipboard}
          onChange={handleTextChange}
        />
        <Typography variant="caption" style={{ marginTop: "20px" }}>
          Last updated: {lastUpdated && `(${timeSince(lastUpdated)})`}
        </Typography>
      </div>
    </div>
  );
};

export default Clipboard;
