import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  Avatar,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { SquaredButtonGreen } from "../../MUI_Components/Components";
import { useAuth } from "../../firebase/contexts/AuthContext";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Visibility, VisibilityOff, PhotoCamera } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const UserProfileModal = ({ open, handleClose }) => {
  const { currentUser } = useAuth();
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [displayName, setDisplayName] = useState(currentUser.displayName);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState("");
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const storage = getStorage();

  useEffect(() => {
    if (profilePicture) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicturePreview(reader.result);
      };
      reader.readAsDataURL(profilePicture);
    } else {
      setProfilePicturePreview("");
    }
  }, [profilePicture]);

  const reauthenticate = async (currentPassword) => {
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );
    try {
      await reauthenticateWithCredential(currentUser, credential);
    } catch (error) {
      throw new Error("Re-authentication failed. Please try again.");
    }
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
      setUnsavedChanges(true);
    }
  };

  const handleProfilePictureUpload = async () => {
    if (profilePicture) {
      const profilePicRef = ref(storage, `profilePictures/${currentUser.uid}`);
      await uploadBytes(profilePicRef, profilePicture);
      const url = await getDownloadURL(profilePicRef);
      setProfilePictureURL(url);
      return url;
    }
    return null;
  };

  function isValidString(str) {
    const regex = /[^a-zA-Z0-9]/;
    return !(regex.test(str) || str.length > 20);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (displayName && !isValidString(displayName)) {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "Display name cannot contain spaces or special characters"
      );
      setSnackbarOpen(true);
      return;
    }
    try {
      if (currentPassword && newPassword) {
        await reauthenticate(currentPassword);
        await updatePassword(currentUser, newPassword);
      }

      const profileUpdates = {};
      if (displayName) profileUpdates.displayName = displayName;
      if (profilePicture)
        profileUpdates.photoURL = await handleProfilePictureUpload();

      if (displayName || profilePicture) {
        await updateProfile(currentUser, profileUpdates);
      }

      setSnackbarSeverity("success");
      setSnackbarMessage("Profile updated successfully!");
      setSnackbarOpen(true);
      setUnsavedChanges(false);
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  };

  const handleModalClose = () => {
    if (unsavedChanges) {
      setConfirmationOpen(true);
    } else {
      resetForm();
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmationOpen(false);
    resetForm();
    handleClose();
  };

  const handleCancelClose = () => {
    setConfirmationOpen(false);
  };

  const resetForm = () => {
    setNewPassword("");
    setCurrentPassword("");
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setDisplayName("");
    setProfilePicture(null);
    setProfilePicturePreview("");
    setProfilePictureURL("");
    setSnackbarMessage("");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            User Profile Settings
          </Typography>
          <Divider sx={{ my: 2 }} />
          <form onSubmit={handleSubmit}>
            <Typography variant="subtitle1">Profile Information</Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Avatar
                src={profilePicturePreview || currentUser.photoURL}
                alt="Profile Picture"
                sx={{ width: 56, height: 56, marginBottom: "20px" }}
              />
              <SquaredButtonGreen
                variant="contained"
                component="label"
                startIcon={<PhotoCamera />}
              >
                Upload Picture
                <input
                  type="file"
                  hidden
                  onChange={handleProfilePictureChange}
                />
              </SquaredButtonGreen>
            </div>
            <TextField
              fullWidth
              margin="normal"
              label="Display Name"
              value={displayName}
              onChange={(e) => {
                setDisplayName(e.target.value);
                setUnsavedChanges(true);
              }}
            />

            <Divider sx={{ my: 2 }} />

            <Typography variant="subtitle1">Change Password</Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Current Password"
              type={showCurrentPassword ? "text" : "password"}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="New Password"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setUnsavedChanges(true);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ display: "flex", justifyContent: "right" }}>
              <SquaredButtonGreen
                type="submit"
                variant="contained"
                color="primary"
              >
                Save Changes
              </SquaredButtonGreen>
            </div>
          </form>
        </Box>
      </Modal>

      <Dialog open={confirmationOpen} onClose={handleCancelClose}>
        <DialogTitle>{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Are you sure you want to close without
            saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmClose} color="error" variant="contained" autoFocus>
            Close Without Saving
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserProfileModal;
