import { AddReactionOutlined } from "@mui/icons-material";
import {
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import CreateIcon from "@mui/icons-material/Create";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useSettings } from "../../../Contexts/SettingsContext";
import { themeDetails } from "../../../Helper/utils";

const ReactionTooltipComponent = ({
  setTooltipOpen,
  handleAddReact,
  handleOpenPopover,
  index,
  replyIndex,
  handleReply,
  author,
  handleReplyReply,
  handleEditComment,
  editIndex,
  commentAuthor,
  type,
  handleDeleteReply,
  replyAuthor,
  handleDeleteComment,
  boardOwner,
  boardId,
  columnId,
  setReplyingTo,
}) => {
  const { currentUser } = useAuth();
  const { settings } = useSettings();
  const [openOptions, setOpenOptions] = useState(false);

  const buttonStyle = {
    minWidth: "auto",
    padding: 0,
    margin: "0 5px",
  };

  const emojiStyle = {
    fontSize: "1.5rem",
    display: "inline-block",
    padding: 2,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        opacity: 1,
        color: themeDetails[settings.cardColor]?.primary || "white",
      }}
    >
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setTooltipOpen(false);
          handleAddReact("👍", index, replyIndex);
        }}
        style={buttonStyle}
      >
        <Typography variant="h5" component="span" style={emojiStyle}>
          👍
        </Typography>
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setTooltipOpen(false);
          handleAddReact("❤️", index, replyIndex);
        }}
        style={buttonStyle}
      >
        <Typography variant="h5" component="span" style={emojiStyle}>
          ❤️
        </Typography>
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setTooltipOpen(false);
          handleAddReact("😂", index, replyIndex);
        }}
        style={buttonStyle}
      >
        <Typography variant="h5" component="span" style={emojiStyle}>
          😂
        </Typography>
      </Button>
      <div style={{ width: "20px", display: "flex", justifyContent: "center" }}>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
        />
      </div>

      <Tooltip title="More">
        <IconButton
          sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
          onClick={(e) => {
            setTooltipOpen(false);
            e.stopPropagation();
            handleOpenPopover(e, index, replyIndex);
          }}
        >
          <AddReactionOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Reply">
        <IconButton
          sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
          onClick={(e) => {
            e.stopPropagation();
            setTooltipOpen(false);
            setReplyingTo(commentAuthor);
            author
              ? handleReplyReply(index, author)
              : handleReply(index, replyIndex);
          }}
        >
          <ReplyIcon />
        </IconButton>
      </Tooltip>
      <div style={{ width: "20px", display: "flex", justifyContent: "center" }}>
        <Divider orientation="vertical" flexItem />
      </div>
      {(commentAuthor === currentUser.email ||
        replyAuthor === currentUser.email) &&
        !openOptions && (
          <Tooltip title="More options">
            <IconButton
              sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenOptions(true);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        )}

      {commentAuthor === currentUser.email && openOptions && (
        <Tooltip title="Edit">
          <IconButton
            sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(false);
              handleEditComment(index);
            }}
          >
            <CreateIcon />
          </IconButton>
        </Tooltip>
      )}

      {type === "reply" && openOptions && replyAuthor === currentUser.email && (
        <Tooltip title="Delete">
          <IconButton
            sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(false);
              handleDeleteReply(index, replyIndex);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      {type !== "reply" &&
        openOptions &&
        commentAuthor === currentUser.email && (
          <Tooltip title="Delete">
            <IconButton
              sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(false);
                handleDeleteComment(index);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
    </div>
  );
};

export default ReactionTooltipComponent;
