import {
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  SquaredButtonGray,
  SquaredButtonGreen,
  TeldioCheckbox,
  TeldioTextField,
} from "../../../MUI_Components/Components";
import CloseIcon from "@mui/icons-material/Close";
import { getDatabase, ref, push, set } from "firebase/database"; // Correct import
import { useAuth } from "../../../firebase/contexts/AuthContext";

const PollPopover = ({ boardPath, columnId, taskId, logAudit, taskName }) => {
  const [fields, setFields] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [pollQuestion, setPollQuestion] = useState("");
  const [selectMultiple, setSelectMultiple] = useState(false);
  const [otherField, setOtherField] = useState(false);
  const { currentUser } = useAuth();

  function handleNewOption() {
    if (newOption === "") {
      return;
    }
    setFields((fields) => [...fields, newOption]);
    setNewOption("");
  }

  function handleRemoveOption(index) {
    setFields(fields.filter((_, i) => i !== index));
  }

  function handleCreatePoll() {
    if (pollQuestion === "" || fields.length === 0) {
      // Add validation feedback if needed
      return;
    }

    const db = getDatabase();
    const pollsRef = ref(
      db,
      `${boardPath}/columns/${columnId}/tasks/${taskId}/polls/`
    );
    const newPollRef = push(pollsRef);
    const pollData = {
      question: pollQuestion,
      options: fields,
      allowMultiple: selectMultiple,
      allowOtherField: otherField,
      createdAt: Date.now(),
      creator: currentUser.uid,
    };

    set(newPollRef, pollData)
      .then(() => {
        logAudit(
          "Poll Created",
          `${
            currentUser.displayName || currentUser.email
          } has created a poll ${pollQuestion} to card ${taskName}`,
          ` has created a poll ${pollQuestion}`
        );
        setPollQuestion("");
        setFields([]);
        setSelectMultiple(false);
        setOtherField(false);
      })
      .catch((error) => {
        console.error("Error adding poll: ", error);
      });
  }

  return (
    <div
      style={{
        width: "300px",
        display: "flex",
        padding: "10px",
        flexDirection: "column",
        alignItems: "center",
        overflow: "auto",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
        New Poll
      </Typography>
      <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
        Question
      </Typography>
      <TeldioTextField
        size="small"
        fullWidth
        placeholder="Poll Question"
        sx={{ marginBottom: "10px" }}
        onChange={(e) => {
          setPollQuestion(e.target.value);
        }}
        value={pollQuestion}
      />
      <Divider sx={{ width: "100%", marginY: 2 }} />

      <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
        Settings
      </Typography>
      <div
        style={{ display: "flex", alignItems: "left", flexDirection: "column" }}
      >
        <FormControlLabel
          fullWidth
          sx={{ marginRight: "1px" }}
          label="Allow Multiple Choices"
          control={
            <TeldioCheckbox
              id={`checkbox-select-multiple`}
              checked={selectMultiple}
              onChange={(e) => setSelectMultiple(!selectMultiple)}
            />
          }
        />
        <FormControlLabel
          fullWidth
          sx={{ marginRight: "1px" }}
          label="Allow Other Field"
          control={
            <TeldioCheckbox
              id={`checkbox-other-field`}
              checked={otherField}
              onChange={(e) => setOtherField(!otherField)}
            />
          }
        />
      </div>
      <Divider sx={{ width: "100%", marginY: 2 }} />

      <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
        Options
      </Typography>

      <TeldioTextField
        size="small"
        fullWidth
        value={newOption}
        placeholder="New Option"
        sx={{ marginBottom: "10px" }}
        onChange={(e) => {
          setNewOption(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleNewOption();
            e.stopPropagation();
          }
        }}
      />
      <SquaredButtonGray fullWidth onClick={handleNewOption}>
        Add Option
      </SquaredButtonGray>
      {fields?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "95%",
            alignItems: "center",
            backgroundColor: "lightgrey",
            borderRadius: "10px",
            padding: 4,
            marginBottom: "10px",
          }}
        >
          {fields?.map((field, index) => (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "95%",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Typography>{field}</Typography>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleRemoveOption(index);
                  }}
                >
                  <CloseIcon color="error" />
                </IconButton>
              </div>
              {index < fields?.length - 1 && (
                <Divider sx={{ width: "90%", color: "red", paddingX: 2 }} />
              )}
            </>
          ))}
        </div>
      )}

      <SquaredButtonGreen
        fullWidth
        sx={{ marginTop: "20px" }}
        onClick={handleCreatePoll}
      >
        Create Poll
      </SquaredButtonGreen>
    </div>
  );
};

export default PollPopover;
