import {
  Box,
  Card,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors } from "../../../Helper/utils";
import { SquaredButtonGray } from "../../../MUI_Components/Components";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";
import { useAuth } from "../../../firebase/contexts/AuthContext";

const Cover = ({ cover, closeCover, handleSaveCover, taskId }) => {
  const [selectedColour, setSelectedColour] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [previousCovers, setPreviousCovers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (cover) {
      setSelectedColour(cover);
    }
  }, [cover]);

  useEffect(() => {
    const fetchPreviousCovers = async () => {
      const storage = getStorage();
      const listRef = ref(storage, `taskCovers/${taskId}`);
      const res = await listAll(listRef);
      const urls = await Promise.all(
        res.items.map((item) => getDownloadURL(item))
      );
      setPreviousCovers(urls);
    };
    fetchPreviousCovers();
  }, [taskId]);

  const handleChooseCover = (cover) => {
    setSelectedColour(cover);
    handleSaveCover(cover);
  };

  const handleImageUpload = async (event) => {
    event.stopPropagation();
    const file = event.target.files[0];
    if (!file) return;

    setIsLoading(true);

    const storage = getStorage();
    const storageRef = ref(storage, `taskCovers/${taskId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      () => {},
      (error) => {
        console.error("Upload failed:", error);
        setIsLoading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setImagePreview(downloadURL);
        handleSaveCover(downloadURL);
        setPreviousCovers((prev) => [...prev, downloadURL]);
        setIsLoading(false);
      }
    );
  };

  const handleDeleteCover = async (coverUrl) => {
    const storage = getStorage();
    const coverRef = ref(storage, coverUrl);

    try {
      await deleteObject(coverRef);
      setPreviousCovers((prev) => prev.filter((url) => url !== coverUrl));
      if (selectedColour === coverUrl) {
        setSelectedColour(null);
        handleSaveCover(null);
      }
    } catch (error) {
      console.error("Delete failed:", error);
    }
  };

  return (
    <Card
      sx={{ width: "300px", height: "auto", padding: "0px 15px 15px 15px" }}
      onClick={(e) => {
        e.stopPropagation();
      }} // Prevent clicks inside the card from closing the modal
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          padding: "15px",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Typography sx={{ width: "100%" }}>Cover</Typography>
        <IconButton
          sx={{ position: "absolute", right: 4 }}
          onClick={(e) => {
            e.stopPropagation();
            closeCover(e);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Typography variant="subtitle2">Colours</Typography>
      <Grid
        container
        spacing={1}
        sx={{ marginTop: "2px" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {colors.map((color) => (
          <Grid item xs={3} key={color}>
            <Box
              sx={{
                width: "100%",
                height: "30px",
                backgroundColor: color,
                cursor: "pointer",
                border:
                  selectedColour === color
                    ? "2px solid #000"
                    : "2px solid transparent",
                borderRadius: "4px",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.1)",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                selectedColour === color
                  ? handleChooseCover(null)
                  : handleChooseCover(color);
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="subtitle2" sx={{ marginTop: "10px" }}>
        Attachments
      </Typography>
      <SquaredButtonGray fullWidth sx={{ marginTop: "10px" }} component="label">
        Upload an Image
        <input
          type="file"
          accept="image/*"
          hidden
          onClick={(e) => e.stopPropagation()} // Prevent modal from closing
          onChange={(e) => handleImageUpload(e)}
        />
      </SquaredButtonGray>
      {isLoading && (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        >
          <CircularProgress />
        </Box>
      )}
      {previousCovers.length > 0 && (
        <>
          <Typography variant="subtitle2" sx={{ marginTop: "10px" }}>
            Previous Covers
          </Typography>
          <Grid container spacing={1} sx={{ marginTop: "2px" }}>
            {previousCovers.map((url, index) => (
              <Grid
                item
                xs={3}
                key={index}
                sx={{ position: "relative", marginRight: "10px" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50px",
                    backgroundImage: `url(${url})`,
                    backgroundSize: "cover",
                    cursor: "pointer",
                    border:
                      selectedColour === url
                        ? "3px solid #A0AD39"
                        : "3px solid transparent",
                    borderRadius: "4px",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "scale(1.1)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  onClick={() => handleChooseCover(url)}
                />
                <IconButton
                  sx={{ position: "absolute", top: -5, right: -20 }}
                  onClick={() => handleDeleteCover(url)}
                >
                  <CloseIcon fontSize="small" color="error" />
                </IconButton>
              </Grid>
            ))}
          </Grid>
          <SquaredButtonGray
            fullWidth
            sx={{ marginTop: "10px" }}
            component="label"
            onClick={() => {
              handleChooseCover(null);
            }}
          >
            Remove Current Cover
          </SquaredButtonGray>
        </>
      )}
    </Card>
  );
};

export default Cover;
